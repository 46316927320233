import { Directive } from '@angular/core';

@Directive({
    selector: '[appInfozone]',
    standalone: false
})
export class InfozoneDirective {

  constructor() { }

}
