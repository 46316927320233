import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-desktopnav',
    templateUrl: './desktopnav.component.html',
    styleUrls: ['./desktopnav.component.scss'],
    standalone: false
})
export class DesktopnavComponent implements OnInit {

  public _show:boolean = false;

  get show(){
    // console.log('DesktopnavComponent show '+this.router.url);
    if(
      this.router.url == '/welcomequestionnaire' 
      ||
      this.router.url == '/welcomequestions' 
      || 
      this.router.url == '/fakesplash' 
      || 
      this.router.url == '/login' 
      || 
      this.router.url == '/register' 
      || 
      this.router.url == '/splash' 
      || 
      this.router.url == '/accesscodes'  
      || 
      this.router.url == '/recoverytimeline'    
      || 
      this.router.url == '/introslider'  
      || 
      this.router.url == '/ppolicy'  
      || 
      this.router.url == '/termsandconditions'  
      || 
      this.router.url == '/guest'  
      || 
      this.router.url.startsWith('/guest?')
      || 
      this.router.url == '/postcode-entry'
      || 
      this.router.url.indexOf('/select-practice') != -1
      || 
      this.router.url == '/select-area'
      || 
      this.router.url == '/create-account'
      || 
      this.router.url.match(/\/gateway\/\d+/) != null
      || 
      this.router.url == '/need-help'
      || 
      this.router.url == '/need-help-thankyou'
      || 
      this.router.url == '/feedbackinput'
      ||
      this.router.url == '/local-service-options'
      ||
      this.router.url == '/select-super-organisation'
      ||
      this.router.url == '/welcome'
      ||
      this.router.url == '/nhs-callback'
      ||
      this.router.url.indexOf('/enter-code/') != -1
      ||
      this.router.url.indexOf('/verify/') != -1
      ||
      this.router.url.match(/\/guest(\?.*)?/)
      )
      this._show = false
    else
      this._show = true

    return this._show;
  }

  constructor(
    public router: Router,
    public data: DataService
    ) { 

      this.router.events.subscribe((event) => {
        if(event instanceof NavigationEnd){
          console.log('Navigation End to '+event.url);
          if(
            this.router.url == '/welcomequestionnaire' 
            ||
            this.router.url == '/welcomequestions' 
            || 
            this.router.url == '/fakesplash' 
            || 
            this.router.url == '/login' 
            || 
            this.router.url == '/register' 
            || 
            this.router.url == '/splash' 
            || 
            this.router.url == '/accesscodes'  
            || 
            this.router.url == '/recoverytimeline'    
            || 
            this.router.url == '/introslider'  
            || 
            this.router.url == '/ppolicy'  
            || 
            this.router.url == '/termsandconditions'  
            || 
            this.router.url == '/guest'  
            || 
            this.router.url == '/postcode-entry'
            || 
            this.router.url.indexOf('/select-practice') != -1
            || 
            this.router.url == '/select-area'
            || 
            this.router.url == '/create-account'
            || 
            this.router.url == '/need-help'
            || 
            this.router.url == '/need-help-thankyou'
            || 
            this.router.url == '/feedbackinput'
            ||
            this.router.url == '/local-service-options'
            ||
            this.router.url == '/select-super-organisation'
            ||
            this.router.url == '/welcome'
            ||
            this.router.url == '/nhs-callback'
            ||
            this.router.url.indexOf('/enter-code/') != -1
            ||
            this.router.url.indexOf('/verify/') != -1
            )
            this._show = false
          else
            this._show = true
        }
    });

  }

  label(name):string{
    if(this.data.menu){
      let found = this.data.menu.find((e) => e.name == name)
      if(found && found.custom)
        return found.custom
    }

    return name
  }
  
  logout(){
    this.data.logout();
  }

  ngOnInit() {}

}
