import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Media } from '@ionic-native/media/ngx';
import { LoadingController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-messagebubble',
    templateUrl: './messagebubble.component.html',
    styleUrls: ['./messagebubble.component.scss'],
    providers: [Media],
    standalone: false
})
export class MessagebubbleComponent implements OnInit {
  @Input() text: any;
  @Input() replace_icon: any;
  @Input() replace_icon_name: any;
  media_url: any = '';
  language_id: any;

  constructor(public data: DataService,public media: Media, private loadingCtrl: LoadingController, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.language_id = this.data.user.language_id;
  }

  async play(){
    console.log('ttsmediaplayer: ',this.data.ttsMediaPlayer);
    if(this.data.user.language_id > 0){
      if(this.data.ttsMediaPlayer != undefined){
        for(let i in this.data.ttsMediaPlayerSources){
          if(i != this.media_url){
            if(this.data.ttsMediaPlayerSources[i] == 'playing'){
              this.data.ttsMediaPlayer.pause();
              this.data.ttsMediaPlayer.currentTime = 0;
              //this.data.ttsMediaPlayer.release();
              this.data.ttsMediaPlayer = undefined;
            }
            this.data.ttsMediaPlayerSources[i] = '';
          }
        }
      }
      let self = this;
      if(this.media_url != '' && this.language_id == this.data.user.language_id && this.data.ttsMediaPlayer != undefined){
        this.playMedia();
      } else {
        const loading = await this.loadingCtrl.create({
          message: 'Please wait ...',
          duration: 0,
        });
    
        loading.present();
        this.data.testToSpeach(this.text).then((response: any) => {
          self.media_url = response.url.substring(1);
          console.log('tts response: ', response);
          console.log('media url: ', self.data.basePath + self.media_url);
          self.language_id = self.data.user.language_id;
          self.data.ttsMediaPlayerSources[self.media_url] = '';
          self.data.ttsMediaPlayer = document.getElementById('tts-audio-player');
          self.data.ttsMediaPlayer.src = self.data.basePath + self.media_url;
          self.data.ttsMediaPlayer.load();
          self.data.ttsMediaPlayer.onplaying = (() => {
              loading.dismiss();
              self.data.ttsMediaPlayerSources[self.media_url] = 'playing';
              self.changeDetectorRef.detectChanges();
          });
          self.data.ttsMediaPlayer.onended = (() => {
            for(let i in self.data.ttsMediaPlayerSources){
              self.data.ttsMediaPlayerSources[i] = '';
            }
            self.changeDetectorRef.detectChanges();
           })
          self.playMedia();
        }, err => {
          loading.dismiss();
        });
      }
    }
  }

  playMedia(){
    if(this.data.ttsMediaPlayerSources[this.media_url] == 'playing'){
      this.data.ttsMediaPlayer.pause();
      this.data.ttsMediaPlayerSources[this.media_url] = 'pause';
    } else {
      this.data.ttsMediaPlayer.play();
    }
  }
}
