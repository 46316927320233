import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AlertController, ModalController, IonContent } from '@ionic/angular';
import { QuestionnaireoutputmodalComponent } from '../../components/questionnaireoutputmodal/questionnaireoutputmodal.component'

@Component({
    selector: 'app-blockquestionnaire',
    templateUrl: './blockquestionnaire.component.html',
    styleUrls: ['./blockquestionnaire.component.scss'],
    standalone: false
})
export class BlockquestionnaireComponent implements OnInit {
   @ViewChild(IonContent) content: IonContent;
   @Input() questionnaire: any;
   @Input() redirecturl: any;
   @Output() submited = new EventEmitter<any>();
   public questions: any;
   public step = 1;
   public extra: any = [];
   public infozone: any;
   public button_disabled = false;
   public timeStamp: any;
   public isEmCondition: boolean = false;

   constructor(public data: DataService, public alertController: AlertController, public modalController: ModalController) {

   }

   ngOnInit() {
      console.log("AM AJUNS IN BLOCKQUESTIONNAIRE")
      console.log(this.data.pathway);

      if (this.data?.pathway?.every_days > 0) {
         this.isEmCondition = true;
      }

      if (this.data.pathway != undefined && this.data.pathway.show_traffic_light_page == 0 && this.redirecturl == '/feedbackinput') {
         this.redirecturl = '/home';
      }
      console.log(this.redirecturl);
      this.timeStamp = new Date().getTime();
   }



   async done() {
      let that = this
      const modal = await this.modalController.create({
         component: QuestionnaireoutputmodalComponent,
         animated: false,
         componentProps: {
            infozone: that.infozone,
            basepath: that.data.basePath,
            redirecturl: that.redirecturl
         }
      });
      return await modal.present();
   }

   get extra_message() {
      return this.questionnaire.extra_message ? this.questionnaire.extra_message : 'You have answered "Yes" to this questions:'
   }

   back() {
      this.step = 1
   }

   async submitWithoutExtra() {
      let that = this;
      this.button_disabled = true;
      if (this.questionnaire.blockquestions && this.questionnaire.blockquestions.length) {
         this.questions = this.questionnaire.blockquestions;
      }

      let q_res = this.questions.map(function (q) {
         return {
            id: q.id,
            answer: q.answer,
            extraanswer: '0' // Auto-answer to "No"
         };
      });

      that.data.showAjaxRequestLoader = true;
      let response: any = await that.data.questionnaire({ done: true, type: that.questionnaire.type, id: that.questionnaire.id, questions: q_res });
      localStorage.setItem('questionnaire_answer_id', response.questionnaire_answer_id);
      localStorage.setItem('questionnaire_id', that.questionnaire.id);
      that.data.setEventsAnalytics('Screening passed', 'formSubmission', { product_zone: 'Screening', questionnaire_id: that.questionnaire.id, answer_id: response.questionnaire_answer_id });
      console.log(response);
      that.data.refreshRecovery();

      const modal = await this.modalController.create({
         component: QuestionnaireoutputmodalComponent,
         animated: false,
         componentProps: {
            response: response,
            basepath: that.data.basePath,
            redirecturl: that.redirecturl
         }
      });
      that.data.showAjaxRequestLoader = false;
      that.button_disabled = false;
      that.submited.emit({ done: true, type: that.questionnaire.type, id: that.questionnaire.id, questions: q_res });
      return await modal.present();
   }

   async submit() {
      let that = this;
      this.button_disabled = true;
      if (this.questionnaire.blockquestions && this.questionnaire.blockquestions.length) {
         this.questions = this.questionnaire.blockquestions
      }

      let answersLeft = this.extra.filter((q) => !q.extraanswer)
      let answersNo = this.extra.filter((q) => { return "0" == q.extraanswer })

      if (answersLeft && answersLeft.length) {
         that.presentAlert('Questionnaire warning', '', 'OK', 'Please answer to all questions');
         that.button_disabled = false;
      } else {
         let q_res = that.questions.map(function (q) {
            return {
               'id': q.id,
               'answer': q.answer,
               'extraanswer': q.extraanswer
            }
         });

         const alert = await this.alertController.create({
            header: '',
            message: that.questionnaire.extra_confirmation ? that.questionnaire.extra_confirmation : 'Please press <b>"CONFIRM"</b> if you have <b>NOT</b> discussed your new or worsening symptom/problem with a clinician OR press <b>"BACK"</b> to change your answer',
            buttons: [
               {
                  text: 'Back',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: (blah) => {
                     console.log('Confirm Back: blah');
                     that.data.setEventsAnalytics('Back Seen a clinician', 'formSubmission', { product_zone: 'Screening', questionnaire_id: that.questionnaire.id });
                     that.button_disabled = false;
                  }
               }, {
                  text: 'Confirm',
                  handler: async () => {
                     alert.dismiss();
                     that.data.setEventsAnalytics('Confirm Not Seen', 'formSubmission', { product_zone: 'Screening', questionnaire_id: that.questionnaire.id });

                     that.data.showAjaxRequestLoader = true;

                     let response: any = await that.data.questionnaire({ done: true, type: that.questionnaire.type, id: that.questionnaire.id, questions: q_res });
                     localStorage.setItem('questionnaire_answer_id', response.questionnaire_answer_id);
                     localStorage.setItem('questionnaire_id', that.questionnaire.id);
                     that.data.setEventsAnalytics('Screening passed', 'formSubmission', { product_zone: 'Screening', questionnaire_id: that.questionnaire.id, answer_id: response.questionnaire_answer_id });
                     console.log(response);
                     that.data.refreshRecovery();

                     const modal = await this.modalController.create({
                        component: QuestionnaireoutputmodalComponent,
                        animated: false,
                        componentProps: {
                           response: response,
                           basepath: that.data.basePath,
                           redirecturl: that.redirecturl
                        }
                     });
                     that.data.showAjaxRequestLoader = false;
                     that.button_disabled = false;
                     that.submited.emit({ done: true, type: that.questionnaire.type, id: that.questionnaire.id, questions: q_res })
                     return await modal.present();
                  }
               }
            ]
         });
         if (answersNo.length) {
            alert.present();
         } else {
            that.data.showAjaxRequestLoader = true;
            that.submited.emit({ done: true, type: that.questionnaire.type, id: that.questionnaire.id, questions: q_res })
            let response = await that.data.questionnaire({ done: true, type: that.questionnaire.type, id: that.questionnaire.id, questions: q_res })
            console.log(response);
            that.data.refreshRecovery();

            const modal = await this.modalController.create({
               component: QuestionnaireoutputmodalComponent,
               animated: false,
               componentProps: {
                  response: response,
                  basepath: that.data.basePath,
                  redirecturl: that.redirecturl
               }
            });
            that.data.showAjaxRequestLoader = false;
            that.button_disabled = false;
            return await modal.present();
         }

         // if(this.extra.length){
         //   this.step = 2;
         // }else
      }
   }

   segmentChanged(event) {
      this.extra = this.questionnaire.blockquestions.filter((q) => q.answer == '1');
      if (event.srcElement.parentElement.parentElement.nextElementSibling) {
         event.srcElement.parentElement.parentElement.nextElementSibling.scrollIntoView({ behavior: 'smooth' });
      }

      if (this.step == 2) {
         if (event.target.value == '0') {
            this.data.setEventsAnalytics('Not Seen Clinician', 'formSubmission', { product_zone: 'Screening', questionnaire_id: this.questionnaire.id });
         } else {
            this.data.setEventsAnalytics('Seen Clinician', 'formSubmission', { product_zone: 'Screening', questionnaire_id: this.questionnaire.id });
         }
      }
   }

   async next() {
      let that = this;
      that.button_disabled = true;
      if (this.questionnaire.blockquestions && this.questionnaire.blockquestions.length) {
         this.questions = this.questionnaire.blockquestions
      }

      let answersLeft = this.questions.filter((q) => !q.answer)
      if (answersLeft && answersLeft.length) {

         that.presentAlert('Questionnaire warning', '', 'OK', 'Please answer to all questions');
         that.button_disabled = false;
      } else {
         that.button_disabled = false;
         let q_res = that.questions.map(function (q) {
            return {
               'id': q.id,
               'answer': q.answer
            }
         });
         this.extra = this.questionnaire.blockquestions.filter((q) => q.answer == '1')

         if (this.extra.length) {
            this.data.scrollToTop()
            this.step = 2;
            that.data.setEventsAnalytics('Screening Safetynetted', 'formSubmission', { product_zone: 'Screening', questionnaire_id: that.questionnaire.id });
         } else {
            that.button_disabled = true;
            that.data.showAjaxRequestLoader = true;
            that.submited.emit({ done: true, type: that.questionnaire.type, id: that.questionnaire.id, questions: q_res })
            let response: any = await that.data.questionnaire({ done: true, type: that.questionnaire.type, id: that.questionnaire.id, questions: q_res })
            console.log(response);
            that.data.setEventsAnalytics('Screening Notsafetynetted', 'formSubmission', { product_zone: 'Screening', questionnaire_id: that.questionnaire.id, answer_id: response.questionnaire_answer_id });

            const modal = await this.modalController.create({
               component: QuestionnaireoutputmodalComponent,
               animated: false,
               componentProps: {
                  response: response,
                  basepath: that.data.basePath,
                  redirecturl: that.redirecturl
               }
            });
            that.data.showAjaxRequestLoader = false;
            that.button_disabled = false;
            return await modal.present();
         }

      }
   }

   setQuestions(questions: any) {
      this.questions = questions
   }

   async presentAlert(header: string, subHeader: string, button: string, response: string) {

      const alert = await this.alertController.create({
         header: header,
         subHeader: subHeader,
         message: response,
         buttons: [button]
      });

      await alert.present();
   }

}