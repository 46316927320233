import { ErrorHandler, Injectable, Injector, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FCM } from '@capacitor-community/fcm';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { AlertController, LoadingController, MenuController, ModalController, ToastController } from '@ionic/angular';
import { InAppReview } from '@capacitor-community/in-app-review';
import { EventEmitter } from 'events';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MfaService } from './mfa.service';


import {
  PushNotifications,
} from '@capacitor/push-notifications';

import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { HostListener } from "@angular/core";
import { Platform } from '@ionic/angular';
import { catchError, finalize, map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

interface UserProgress {
  title: string
  id: number
}

@Injectable({
  providedIn: 'root'
})

export class DataService implements OnChanges {


  /* Show or hide MQA modal */
  public showMqaModal = false;

  /* Holds the fetched MQA data if present */
  public mqaQuestionnaireData: any = null;

  //global.app.version
  //will be changed by prebuild scipts
  public appversion = "6.123.14";
  public access: any;
  public _basePath: string = environment.apiUrl;
  // public _basePath: string = "https://gap.test/";
  public mainMenu: any = [];
  public pathways: any = [];
  public todo: any = null;
  public timestamp: string;
  public timer: any;
  public diag: any = {}
  public scrollTop: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public exerciseLevel = new EventEmitter();
  public diaryUpdated = new EventEmitter();
  public maintenance_mode: boolean = false;
  public showSpinnerLoader: boolean = false;
  public showSpinnerLoaderTimeout: any;
  public networkConnection: any;
  public dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public ttsMediaPlayer: any;
  public ttsMediaPlayerSources: any = {};
  public displayAppInfo = false;
  public disable_touch_to_speak = '1';
  public gaEvent: any;
  public pnsToken: any;
  public customAlert: any;
  public enable_app_store_rating: any = '0';
  public disable_nhs_login: any = '1';
  public enable_feedback_questionnaire: any = '1';

  public otpcode_exp_time: number = 10 * 60 * 1000; // 10 minutes in milliseconds
  public mfaAuth: MfaService;
  public otpResendDelay: number = 5 * 1000; // 5 seconds

  static visited_nhs_settings?: any = false;

  get isUserLogged(): boolean {
    return localStorage.getItem('isUserLogged') == 'true';
  }

  set isUserLogged(value: boolean) {
    localStorage.setItem('isUserLogged', value.toString());
  }

  get email(): string {
    return this.mfaAuth.email;
  }

  set email(value: string) {
    this.mfaAuth.email = value;
    // Update DataService's user data if necessary
    const user = this.user;
    if (user) {
      user.email = value;
    }
    this.user = user;
  }

  get phone(): string {
    return this.mfaAuth.phone;
  }

  set phone(value: string) {
    this.mfaAuth.phone = value;
    // Update DataService's user data if necessary
    const user = this.user;
    if (user) {
      user.phone = value;
    }
    this.user = user;
  }

  // Getter and setter for mfaFlow
  get mfaFlow(): 'register' | 'login' {
    return this.mfaAuth.mfaFlow;
  }

  set mfaFlow(value: 'register' | 'login') {
    this.mfaAuth.mfaFlow = value;
  }

  get loginResponse(): any {
    const val = this.mfaAuth.loginResponse;
    const storedValue = localStorage.getItem('loginResponse');

    if (storedValue !== null) {
      return JSON.parse(storedValue);
    } else {
      return val;
    }
  }

  set loginResponse(value: any) {
    localStorage.setItem('loginResponse', JSON.stringify(value));
    this.mfaAuth.loginResponse = value;
  }

  get accountMfaVerified(): boolean {
    return this.mfaIsAuthentificated();
  }

  get mqaQuestionnaire(): any {

    // delete after i get it
    const storedValue = localStorage.getItem('mqaQuestionnaire');
    if (storedValue !== null) {
      localStorage.removeItem('mqaQuestionnaire');
      return JSON.parse(storedValue);
    } else {
      return null;
    }
  }

  set mqaQuestionnaire(value: any) {
    localStorage.setItem('mqaQuestionnaire', JSON.stringify(value));
  }


  platformWidth() {
    if (!(this.platform.is('android') || this.platform.is('ios')))
      return 640;
    else
      return 320
  }

  platformHeight() {
    if (!(this.platform.is('android') || this.platform.is('ios')))
      return 320;
    else
      return 240
  }

  getVimeoID(video): string {
    return 'vimeo-player-' + video.vimeovideo.replace('/', '');
  }

  vimeowidth(video) {
    let ratio = video.width / video.height;

    return this.platformWidth();
  }

  vimeoheight(video) {
    if (video.height == 0) {
      return 240;
    }
    const aspectRatio = video.width / video.height;
    return this.platformWidth() / aspectRatio;
  }

  vimeolink(video) {
    if (video.vimeovideo)
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video' + video.vimeovideo);
    else
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/407508746');
  }

  private predefinedProtectedRoutes(): string[] {
    return [
      '/home',
      '/diary',
      '/feedback',
      '/feedbackinput',
      '/recoverytimeline',
      '/accesscodes',
    ];
  }

  verifyIfRouteIsUnderMfa(routePath): boolean {
    const isRoute = this.predefinedProtectedRoutes().includes(routePath);
    if (isRoute && this.enable_login_mfa == '1') {
      return true;
    } else {
      return false;
    }
  }


  mfaIsAuthentificated(): boolean {
    const storedValue = localStorage.getItem('mfaAuthValidated');
    this.mfaAuth.mfaAuthValidated = storedValue === 'true';
    if (storedValue !== null) {
      return JSON.parse(storedValue)? true : false;
    } else {
      // todo: Implement API call if necessary
      return false;
    }
  }

  generateMfaCode(type: 'phone' | 'email' = 'phone', phone: string): Promise<any> {
    this.mfaAuth.mfaNow();
    return this.apiPostPremise('generateMFA', {
        type: type,
        phone: phone,
        token: this.token,
      });
  }

  // Delegate other MFA methods
  mfaNow(flow: 'register' | 'login' | '' = '') {
    this.mfaAuth.mfaNow(flow);
  }

  mfaValidate(val: boolean = true) {
    if (val === undefined) {
      val = true;
    }
    this.mfaAuth.mfaValidate(val);
  }

  mfaInvalidate() {
    this.mfaAuth.mfaInvalidate();
  }

  @HostListener('window:error', ['$event'])
  handleKeyDown(event: ErrorEvent) { 
    console.log("ErrorEvent")
    console.log(event)
  }

  constructor(
    public router: Router,
    public http: HttpClient,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public platform: Platform,
    public menuCtrl: MenuController,
    public route: ActivatedRoute,
    public modalController: ModalController,
    public sanitizer: DomSanitizer
  ) {

    localStorage.removeItem('router_history');
    let that = this;
    this.mfaAuth = MfaService.getInstance();  // MfaService Singleton

    platform.ready().then(() => {
      Device.getInfo().then((info: any) => {

        if (info.platform != 'web') {
          this.diag.cordova = info.cordova;
          this.diag.isVirtual = info.isVirtual;
          this.diag.manufacturer = info.manufacturer;
          this.diag.version = info.osVersion;
          this.diag.platform = info.platform;
          this.diag.serial = info.name;
          this.diag.model = info.model;

          App.getInfo().then((info: any) => {
            console.log('app info: ', info);
            this.diag.getAppName = info.name;
            this.diag.getPackageName = info.id;
            this.diag.getVersionCode = info.build;
            this.diag.getVersionNumber = info.version;
          });

          Device.getId().then((info: any) => {
            this.diag.uuid = info.identifier;
          });
        } else {
          this.diag.cordova = null
          this.diag.isVirtual = null;
          this.diag.manufacturer = null;
          this.diag.version = null;
          this.diag.platform = null;
          this.diag.serial = null;
          this.diag.model = null;
          this.diag.getAppName = null;
          this.diag.getPackageName = null;
          this.diag.getVersionCode = null;
          this.diag.getVersionNumber = null;
        }
      });

      // this.version.getVersionNumber().then((e:any) => this.diag.getVersionNumber = e).catch((e:any) => this.diag.getVersionNumber = e)
      console.log('ready')
      console.log(this)

    });

     this.router.events.subscribe((event) => {
       // When the user navigates to a new page, store the URL in the routerHistory array
      if (event instanceof NavigationEnd) {
        that.routerHistory = event.url;
        if (event.url == '/home') {
            setTimeout(async () => {
            await this.fetchMqaQuestionnaire();
            }, 2000);
        }
        if (event.url != '/') {

          this.setEventsAnalytics(event.url, 'pageview').then((response: any) => {
            if (response.user != undefined && response.user.display_nhs_found_popup != undefined && event.url == '/home') {
              that.presentAlertActions('NHS Number found', 'Your GP Practice will now be updated on the progress of your condition.', [{
                text: 'Ok',
                handler: () => {
                  that.setUserSettings([
                    {
                      nhs_notification_displayed: '1',
                      nhs_notification_displayed_date: moment().format('YYYY-MM-DD H:mm:ss')
                    }
                  ]);
                }
              }]);
            }
            if (response.user != undefined && ((response.user.nhs_status != undefined && response.user.nhs_status != 'found') || (event.url == '/accountdetails'))) {
              let cloneUser = JSON.parse(JSON.stringify(that.user));
              cloneUser.nhs_status = response.user.nhs_status;
              if (response.user.nhs_action != undefined) {
                cloneUser.nhs_action = response.user.nhs_action;
              } else if (cloneUser.nhs_action != undefined) {
                delete cloneUser.nhs_action;
              }
              that.user = cloneUser;
            }
          })
        }
        this.setSession();
      }

        // When the user navigates to a new page, store the URL in the routerHistory array
      if (event instanceof NavigationStart) {
        that.stopTTSMedia();
        console.log(event.url);
        
        // check if the user routes that are under dome
        if (this.verifyIfRouteIsUnderMfa(event.url) && !this.mfaIsAuthentificated())
        {
          console.log('mfa is not authentificated');
          this.logout(false);
          this.router.navigate(['guest'], { replaceUrl: true });
        }

        if (that.pathway != undefined && that.pathway.show_timeline_page == 0 && event.url == '/recoverytimeline') {
          that.router.navigateByUrl('/home');
        }
        if (that.pathway != undefined && that.pathway.show_traffic_light_page == 0 && event.url == '/feedbackinput') {
          that.router.navigateByUrl('/home');
        }
      }
      // console.log(event);
    });
  }

  // Method to update phone during registration
  updatePhone(newPhone: string): Promise<any> {
    return this.apiPostPremise('updatePhone', {
      phone: newPhone,
      token: this.token,
    }).then((response: any) => {
      if (response.status === 'success') {
        this.phone = newPhone;
        return response;
      } else {
        return Promise.reject(response);
      }
    });
  }

  public scrollToTop() {
    console.log("scrollToTop")
    this.scrollTop.next(true);
  }

  ngOnChanges(changes) {
    console.log("DataService changes")
    console.log(changes)
  }

  firstAppOpen() {
    if (!localStorage.getItem('first_app_open')) {
      this.setEventsAnalytics('firstAppOpen', 'event');
      localStorage.setItem('first_app_open', '' + new Date().getTime());
    }
  }

  setSession() {
    if (this.isUserLogged) {
      let session_start: any = localStorage.getItem('session_start');
      if (!session_start || (Math.abs(new Date().getTime() - session_start) / 36e5 > 1)) {
        this.setEventsAnalytics('session_start', 'event');
      }
      localStorage.setItem('session_start', '' + new Date().getTime());
    }
  }

  // LOCKOUT FUNCTIONALITY

  loadUserStatus() {
    // Fetch user status from backend
    this.http.get('/api/user/status').subscribe((status: any) => {
      this.user.islocked_out = status.islocked_out;
      if (this.user.islocked_out) {
        // Handle redirection if necessary
      }
    });
  }

  // Check if the user is locked out
  checkLockoutStatus() {
     if ((this.pathway?.every_days ?? 1) > 0) {
       console.log('checking lockout status');
    }
  }

  // Set lockout status
  setLockout(status: boolean) {
    this.user.islocked_out = status;
    // Optionally, persist this status in storage
    this.http.post('/api/user/lockout', { islocked_out: status }).subscribe();

    localStorage.setItem('islocked_out', JSON.stringify(status));
  }

  lockoutGuard() {
    if (this.user?.islocked_out) {
      this.router.navigate(['/access-denied'], { replaceUrl: true });
      return false;
    }
    return true;
  }

  // Check if questionnaire was completed today
  isQuestionnaireCompletedToday(questionnaire_id: number|null|undefined = null): boolean {
    const lastCompleted = localStorage.getItem('last_questionnaire_date_q_id' + (questionnaire_id ?? this.treatment.introquestionnaire.id));
    if (lastCompleted) {
      const today = new Date().toISOString().split('T')[0];
      return lastCompleted === today;
    }
    return false;
  }

  // Mark questionnaire as completed today
  markQuestionnaireCompleted(questionnaire_id: number|null|undefined = null) {
    const today = new Date().toISOString().split('T')[0];
    localStorage.setItem('last_questionnaire_date_q_id' + (questionnaire_id ?? this.treatment.introquestionnaire.id), today);
  }

  addDaysToday(numberOfDaysToAdd: number|string = 0) {
    const date = new Date();
    const daysToAdd = typeof numberOfDaysToAdd === 'string' ? parseInt(numberOfDaysToAdd, 10) : numberOfDaysToAdd;
    if (daysToAdd) {
      date.setDate(date.getDate() + daysToAdd);
    }

    if (this.user?.id && this.pathway?.id) {
      localStorage.setItem(`today_user_id_${this.user.id}_pathway_id_${this.pathway.id}`,
        date.toISOString().split('T')[0]);
    } else {
      console.error('User or pathway is undefined.');
    }
    
    // set timeout to restore this value to today after 24 hours
    setTimeout(() => {
      this.today = new Date().toISOString().split('T')[0];
    }, this.timeUntilMidnight());

  }

  timeUntilMidnight() {
    const now = new Date();
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0);
    return midnight.getTime() - now.getTime();
  }

  get today(): string {
    let key = 'today_user_id_' + this.user.id + '_pathway_id_' + this.pathway.id;
    let today = localStorage.getItem('today_user_id_' + this.user.id + '_pathway_id_' + this.pathway.id);
    if (!today) {
      today = new Date().toISOString().split('T')[0];
      localStorage.setItem('today_user_id_' + this.user.id + '_pathway_id_' + this.pathway.id, today);
    }
    //reset today to today after 24 hours
    setTimeout(() => {
      localStorage.removeItem(key);
    }, this.timeUntilMidnight());

    console.log('today is:', today);
    return today;
  }

  set today(value: string) {
    localStorage.setItem('today_user_id_' + this.user.id + '_pathway_id_' + this.pathway.id, new Date(value).toISOString().split('T')[0]);
  }

  public clearAllKeysWithPrefixes(prefixes: string[] = []): Promise<void> {
    prefixes = prefixes.length ? prefixes : ['mqaLastFetchAt', 'mqaSubmissionTimestamp', 'mqaQuestionnaireData'];

    return new Promise((resolve, reject) => {
      const keysToRemove: string[] = [];

      // Collect all matching keys first
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && prefixes.some(prefix => key.startsWith(prefix))) {
          keysToRemove.push(key);
        }
      }

      // Remove collected keys
      keysToRemove.forEach(key => localStorage.removeItem(key));
    });
  }
      
  public getMqaFetchDataKey(): string {
    return 'mqaQuestionnaireData_user_id_' + this.user.id + '_pathway_id_' + this.pathway.id;
  }

  private hasMqaBeenFetched(): boolean {
    const key = 'mqaLastFetchAt' + this.user.id + '-' + this.pathway?.id;
    const fetchedAtStr = JSON.parse(localStorage.getItem(key));
    
    if (!fetchedAtStr) {
      return false;
    }
    const diff = Date.now() - fetchedAtStr;

    setTimeout(() => {
      localStorage.removeItem(key);
    }, (60000 * 60 * 8) + 5000); // 8 hours);

    // Return true only if less than 20 minutes have passed
    const result = diff < 60000 * 60 * 8;
    return result;
  }

  private setMqaFetched(value: boolean): void {
    const key = 'mqaLastFetchAt' + this.user.id + '-' + this.pathway?.id;

    if (!value) {
      localStorage.removeItem(key);
      return;
    }
    const now = Date.now();
    localStorage.setItem(key, JSON.stringify(now));
  }

  fetchMqaQuestionnaire() {
    // console.log('today is (changedday):', this.today);
    // console.log('showMqaModal:', this.showMqaModal);
    // console.log('hasMqaBeenFetched:', this.hasMqaBeenFetched());
    // console.log('enable_feedback_questionnaire:', this.enable_feedback_questionnaire);
    
    if (this.enable_feedback_questionnaire == '0') {
      return;
    }

    if (this.showMqaModal ||
      !this.pathway?.id ||
      // this.hasMqaBeenSubmitted() ||
      this.hasMqaBeenFetched()
    ) {
      return;
    }

    console.log('Fetching MQA data');
    this.setMqaFetched(true);

    try {
      this.httpPost('feedbackQuestionnaire').then((response: any) => {
        if (response?.questions?.length) {
          localStorage.setItem(this.getMqaFetchDataKey(), JSON.stringify(response));
          this.mqaQuestionnaireData = response;
          this.showMqaModal = true;
        }
      }, (err) => {
        console.error('Error fetching MQA data', err);
      });
    } catch (error) {
      console.error('Error fetching MQA data:', error);
    }
  }

  isToday(timestamp: string | null = null, key: string | null = null): boolean {
    if (!timestamp) {
      return new Date().toISOString().split('T')[0] === this.today;
    }
    const date1 = new Date(+timestamp);
    const date2 = new Date(this.today);
    const sameDay = date1.getDate() === date2.getDate() && 
                    date1.getMonth() === date2.getMonth() &&
                    date1.getFullYear() === date2.getFullYear();
    if (!sameDay && key) {
      localStorage.removeItem(key);
    }
    return sameDay;
  }

  onMqaCompleted(event: any) {
    console.log('onMqaCompleted:', event);
    if (event.success) {
      this.showMqaModal = false;
      localStorage.removeItem(this.getMqaFetchDataKey());
    } else {
      this.showMqaModal = false;
    }

    setTimeout(() => {
      this.clearAllKeysWithPrefixes(['mqaLastFetchAt', 'mqaSubmissionTimestamp', 'mqaQuestionnaireData']);
    }, this.timeUntilMidnight());
  }
   
   get isDesktop(): boolean {
      return this.platform.is('desktop');
   }

   get isMobile(): boolean {
      return this.platform.is('mobile');
   }

  set recovery(recovery: any) {
    this.day = recovery.day;
    this.day_message = recovery.day_message
    this.timelineIntervals = recovery.timelineintervals;
    this.progress = recovery.progress;
    this.gateway = recovery.gateway;
    this.painscore = recovery.painscore; // to ask GUB
    this.treatment = recovery.treatment;
    this.worse3times = recovery.worse3times;
    this.aim = recovery.aim;
    this.last_progress = recovery.last_progress;
  }

  get last_progress(): any {
    try {
      return JSON.parse(localStorage.getItem('last_progress'));
    } catch (err) { return false }
  }

  set enable_login_mfa(value) {
    localStorage.setItem('enable_login_mfa', value);
  }

  get enable_login_mfa() {
    //delete all localstorage and return true
    // localStorage.clear();
  if (window.location.hostname === 'localhost' && (window.location.port >= '8100' && window.location.port <= '8110')) {
    return '1';
  }

    return localStorage.getItem('enable_login_mfa');
  }

  set enable_registration_phone_validation(value){
    localStorage.setItem('enable_registration_phone_validation', value);
  }

  get enable_registration_phone_validation(){
    return localStorage.getItem('enable_registration_phone_validation');
  }

  set last_progress(last_progress) {
    if (!last_progress) {
      console.log(this.recovery);
      throwError('no last progress');
    }
    localStorage.setItem('last_progress', JSON.stringify(last_progress));
  }

  get timeline(): any {
    return JSON.parse(localStorage.getItem('active_timeline'));
  }

  get appointments(): any {
    try {
      return JSON.parse(localStorage.getItem('appointments'));
    } catch (Ex) {
      return {};
    }
  }

  get guest_id() {
    let val: any = localStorage.getItem('guest_id');
    if (!val) {
      val = Date.now();
      this.guest_id = val;
    }
    return val;
  }

  set guest_id(val) {
    localStorage.setItem('guest_id', val);
  }

  get hideNhsNumberPopup() {
    return localStorage.getItem('hide_nhs_number_popup');
  }

  set hideNhsNumberPopup(value: any) {
    localStorage.setItem('hide_nhs_number_popup', value);
  }

  get userttslanguages(): any {
    return JSON.parse(localStorage.getItem('userttslanguages'));
  }

  set userttslanguages(userttslanguages) {
    localStorage.setItem('userttslanguages', JSON.stringify(userttslanguages))
  }

  get showAjaxRequestLoader(): boolean {
    let showAjaxRequestLoaderValue = localStorage.getItem('showAjaxRequestLoader');
    return showAjaxRequestLoaderValue == 'yes' ? true : false;
  }

  set showAjaxRequestLoader(value) {
    clearTimeout(this.showSpinnerLoaderTimeout);
    if (value == true) {
      this.showSpinnerLoaderTimeout = setTimeout(() => {
        this.showSpinnerLoader = value;
      }, 5000);
    } else {
      this.showSpinnerLoader = false;
    }

    localStorage.setItem('showAjaxRequestLoader', value == true ? 'yes' : 'no');
  }

  set appointments(appointments: any) {
    localStorage.setItem('appointments', JSON.stringify(appointments))
  }

  get worse3times(): any {
    try {
      return JSON.parse(localStorage.getItem('worse3times'));
    } catch (Ex) {
      return {};
    }
  }

  set services(worse3times: any) {
    localStorage.setItem('services', JSON.stringify(worse3times))
  }

  get services(): any {
    try {
      return JSON.parse(localStorage.getItem('services'));
    } catch (Ex) {
      return [];
    }
  }

  set worse3times(worse3times: any) {
    localStorage.setItem('worse3times', JSON.stringify(worse3times))
  }

  get colors(): any {
    try {
      return JSON.parse(localStorage.getItem('colors'));
    } catch (Ex) {
      return {};
    }
  }

  set colors(colors: any) {
    localStorage.setItem('colors', JSON.stringify(colors))
  }

  get menu(): any {
    try {
      return JSON.parse(localStorage.getItem('menu'));
    } catch (Ex) {
      return [];
    }
  }

  set menu(menu: any) {
    localStorage.setItem('menu', JSON.stringify(menu))
  }

  get letters(): any {
    try {
      return JSON.parse(localStorage.getItem('letters'));
    } catch (Ex) {
      return [];
    }
  }
  set letters(letters: any) {
    if (letters)
      localStorage.setItem('letters', JSON.stringify(letters))
  }

  get diary(): any {
    try {
      return JSON.parse(localStorage.getItem('diary'));
    } catch (Ex) {
      return [];
    }
  }

  set diary(diary: any) {
    if (diary)
      localStorage.setItem('diary', JSON.stringify(diary))
  }

  get diaryDraft(): any {
    try {
      return JSON.parse(localStorage.getItem('diaryDraft'));
    } catch (Ex) {
      return [];
    }
  }

  set diaryDraft(diaryDraft: any) {
    localStorage.setItem('diaryDraft', JSON.stringify(diaryDraft))
  }

  get basePath(): any {
    // destroy the cache
    if (environment.apiUrl === 'https://gap.test/') {
      return 'https://gap.test/';
    }

    return JSON.parse(localStorage.getItem('basePath')) ? JSON.parse(localStorage.getItem('basePath')) : this._basePath;
  }

  set basePath(basePath: any) {
    localStorage.setItem('basePath', JSON.stringify(basePath))
  }

  get fontSize(): any {
    try {
      let size = JSON.parse(localStorage.getItem('fontSize')) ?? 3;
      return size ? size : 3;
    } catch (Ex) {
      console.warn(Ex)
      return 3;
    }
  }
  set fontSize(fontSize: any) {
    localStorage.setItem('fontSize', JSON.stringify(fontSize))
  }
  get fontSizeClass(): string {
    if (this.fontSize == 3)
      return '';
    else
      return 'fontSizeClass' + this.fontSize;
  }
  set fontSizeClass(fontSizeClass: string) {
    localStorage.setItem('fontSizeClass', JSON.stringify(fontSizeClass))
  }
  get treatment(): any {
    return JSON.parse(localStorage.getItem('treatment'));
  }
  set treatment(treatment: any) {
    localStorage.setItem('treatment', JSON.stringify(treatment))
  }
  get painscore(): any {
    return JSON.parse(localStorage.getItem('painscore'));
  }
  set painscore(painscore: any) {
    localStorage.setItem('painscore', JSON.stringify(painscore))
  }
  get feedbackzone(): any {
    return JSON.parse(localStorage.getItem('feedbackzone'));
  }
  set feedbackzone(feedbackzone: any) {
    localStorage.setItem('feedbackzone', JSON.stringify(feedbackzone))
  }
  get boxes(): any {
    return JSON.parse(localStorage.getItem('boxes'));
  }
  set boxes(boxes: any) {
    localStorage.setItem('boxes', JSON.stringify(boxes))
  }
  get helpsections(): any {
    return JSON.parse(localStorage.getItem('helpsections'));
  }
  set helpsections(helpsections: any) {
    localStorage.setItem('helpsections', JSON.stringify(helpsections))
  }
  get endpoint(): any {
    return JSON.parse(localStorage.getItem('endpoint'));
  }
  set endpoint(endpoint: any) {
    localStorage.setItem('endpoint', JSON.stringify(endpoint))
  }

  get infozones(): any {
    return JSON.parse(localStorage.getItem('infozones'));
  }
  set infozones(infozones: any) {
    localStorage.setItem('infozones', JSON.stringify(infozones))
  }

  get day(): any {
    return JSON.parse(localStorage.getItem('day'));
  }
  set day(day: any) {
    localStorage.setItem('day', JSON.stringify(day))
  }

  // get intro(): any {
  //   return JSON.parse(localStorage.getItem('intro'));
  // }
  // set intro(intro:any){
  //   localStorage.setItem('intro',JSON.stringify(intro))
  // }

  get gateway(): any {
    return JSON.parse(localStorage.getItem('gateway'));
  }
  set gateway(gateway: any) {
    localStorage.setItem('gateway', JSON.stringify(gateway))
  }

  get day_message(): any {
    return JSON.parse(localStorage.getItem('day_message'));
  }
  set day_message(day_message: any) {
    localStorage.setItem('day_message', JSON.stringify(day_message))
  }

  get pathway(): any {
    return JSON.parse(localStorage.getItem('pathway'));
  }
  set pathway(p: any) {
    localStorage.setItem('pathway', JSON.stringify(p))
  }

  get gp(): any {
    try {
      return JSON.parse(localStorage.getItem('gp'));
    } catch (e) {
      return false;
    }
  }
  set gp(gp: any) {
    localStorage.setItem('gp', JSON.stringify(gp))
  }

  get user(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  set user(u: any) {
    console.log('setting user');
    console.log(u);
    localStorage.setItem('user', JSON.stringify(u))
  }


  get nhsLoggedInStatus(): boolean {
    // get from user.nhs_status
    return this.user.login_service == 'nhs';
  }

  set nhsLoggedInStatus(value: boolean) {
    this.user.login_service = value ? 'nhs' : 'gap';
  }

  get nhsToken(): any {
    return JSON.parse(localStorage.getItem('nhs-token'));
  }

  set nhsToken(u: any) {
    localStorage.setItem('nhs-token', JSON.stringify(u))
  }

  get token(): any {
    return JSON.parse(localStorage.getItem('token'));
  }
  set token(u: any) {
    localStorage.setItem('token', JSON.stringify(u))
  }

  get progress(): any {
    return JSON.parse(localStorage.getItem('progress'));
  }

  get avatar(): any {
    if (this.user && this.user.avatar)
      return this.user.avatar.path
    else
      return 'assets/avatar.svg'
  }

  get diagnostic(): any {

    return this.diag;

  }

  get programs(): any {
    try {
      return JSON.parse(localStorage.getItem('programs'));
    } catch (Ex) {
      return [];
    }
  }

  set programs(programs: any) {
    localStorage.setItem('programs', JSON.stringify(programs))
  }

  get programproviders(): any {
    try {
      return JSON.parse(localStorage.getItem('programproviders'));
    } catch (Ex) {
      return [];
    }
  }

  set programproviders(programproviders: any) {
    localStorage.setItem('programproviders', JSON.stringify(programproviders))
  }


  get availableprograms(): any {
    try {
      return JSON.parse(localStorage.getItem('availableprograms'));
    } catch (Ex) {
      return [];
    }
  }

  set availableprograms(availableprograms: any) {
    localStorage.setItem('availableprograms', JSON.stringify(availableprograms))
  }

  get aim(): any {
    return JSON.parse(localStorage.getItem('aim'));
  }

  set aim(aim: any) {
    localStorage.setItem('aim', JSON.stringify(aim))
  }

  get currentAimFeedback(): any {
    return JSON.parse(localStorage.getItem('currentAimFeedback'));
  }

  set currentAimFeedback(currentAimFeedback: any) {
    localStorage.setItem('currentAimFeedback', JSON.stringify(currentAimFeedback))
  }

  get painhistory(): any {
    return JSON.parse(localStorage.getItem('painhistory'));
  }

  set painhistory(painhistory: any) {
    localStorage.setItem('painhistory', JSON.stringify(painhistory))
  }

  get pathwaylabels(): any {
    try {
      let ret = JSON.parse(localStorage.getItem('pathwaylabels'));
      if (ret)
        return ret;
      else
        return []
    } catch (Ex) {
      return [];
    }
  }

  set pathwaylabels(pathwaylabels: any) {
    let done = localStorage.setItem('pathwaylabels', JSON.stringify(pathwaylabels));
  }

  sendLogMessage(message: string|Object) {

    // this.http.post(this.basePath + 'api/logmessage', this.logMessage).subscribe({
    //   next: function (response: any) {
    //     console.log('log message sent');
    //   },
    //   error: function (response) {
    //     console.log('error');
    //     console.log(response);
    //   }
    // })
  }

  pathwaylabel(input: any, section: any): any {
    let labels = this.pathwaylabels;
    let found = this.pathwaylabels.find((item) => item.slug == section + '.' + input && item.id != undefined);

    if (found) {
      return found['custom'];
    }
    else {
      let already_added = this.pathwaylabels.find((item) => item.slug == section + '.' + input);
      if (already_added == undefined) {
        labels.push({
          'slug': section + '.' + input,
          'custom': input
        });
        this.pathwaylabels = labels;
      }
      // this.setpathwaylabel(input, section);
      return input;
    }
  }

  setpathwaylabels() {
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      labels: this.pathwaylabels.filter(function (item) { return item.id == undefined; })
    };

    this.http.post(this.basePath + 'api/pathwaylabel', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        console.log('setpathwaylabel');
        console.log(response);
        that.pathwaylabels = response.labels;
      },
      error: function (response) {
        console.log('error');
        //that.presentAlert('Login error', 'Can\'t login', 'OK', 'Error: ' + response.error.error);
        console.log(response);
      }
    })
  }

  submitReferralQuestionnaire(questions, type) {
    let that = this;
    let payload = {
      token: this.token,
      pathway_id: this.pathway.id,
      questions: questions,
      type: type
    };
    this.http.post(this.basePath + 'api/referral-questionnaire', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        console.log('setpathwaylabel');
        that.appointments = response.appointments;
        that.letters = response.letters;
      },
      error: function (response) {
        console.log('error');
        //that.presentAlert('Login error', 'Can\'t login', 'OK', 'Error: ' + response.error.error);
        console.log(response);
      }
    })
  }


  observer() {
    console.log("observer arguments")
    console.log(arguments)
  }

  async updateProgress(progress: UserProgress, next: any = null) {
    console.log("updateProgress");
    this.showAjaxRequestLoader = true;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      progress: progress.id
    };

    let that = this
    const response: any = await this.http.post(this.basePath + 'api/userprogress', this.payloadMiddleware(payload)).toPromise();
    console.log('userprogress response: ', response);
    this.showAjaxRequestLoader = false;
    if (response && response.recovery) {
      this.recovery = response.recovery
      this.endpoint = response.endpoint;
      console.log('userprogress done');
      this.setEventsAnalytics(progress.title, 'event');
    }

    return response
  }

  @Input()
  set progress(progress: any) {
    console.log('setting progress');
    console.log(progress);
    localStorage.setItem('progress', JSON.stringify(progress))
  }

  stopTTSMedia() {
    if (this.ttsMediaPlayer != undefined) {
      this.ttsMediaPlayer.pause();
      this.ttsMediaPlayer.currentTime = 0;
      for (let i in this.ttsMediaPlayerSources) {
        this.ttsMediaPlayerSources[i] = '';
      }
    }
  }

  getPageName(name){
    name = name && name.indexOf('?') != -1 ? name.split('?')[0] : name;
    if(name){
      switch(true){
        case name == '/introslider':
          name = '/introcarousel';
          break;
        case name == '/guest':
          name = '/login_or_create_account';
          break;
        case name == '/postcode-entry':
          name = '/postcode_entry';
          break;
        case name.indexOf('/select-practice/') != -1:
          name = '/' + (name.split('/')[2]) + '_selection';
          break;
        case name == '/select-area':
          name = '/area_selection';
          break;
        case name == '/need-help':
          name = '/helpform';
          break;
        case name == '/welcome':
          name = '/welcome_to_gub'
          break;
        case name == '/accescodes':
          name = '/select_a_condition';
          break;
        case name == '/support-modules':
          name = '/select_a_support_module';
          break;
        case name == '/create-account':
          name = '/details_entry';
          break;
        case name == '/register':
          name = '/credentials_entry'
          break;
        case name == '/welcomequestions':
          name = '/initial_screening';
          break;
        case name == '/accesscodes':
          name = '/select_a_condition';
          break;
      }
    }

    return name;
  }

  setEventsAnalytics(name, type, custom_parameters?, replace_params?) {
    console.log('name event:', name);
    name = this.getPageName(name);
    
    this.gaEvent = { event: { name: name, type: type } };
    if (custom_parameters) {
      this.gaEvent.event['custom_parameters'] = custom_parameters;
    }
    this.gaEvent.event['guest_id'] = this.guest_id + '';
    this.gaEvent.event['currentpage'] = this.getPageName(this.router.url);
    this.gaEvent.event['previouspage'] = this.getPageName(this.routerHistory[this.routerHistory.length - 2]);
    this.gaEvent.event['suborg_id'] = this.gp ? this.gp.id : '';
    this.gaEvent.event['pathway_id'] = this.pathway ? this.pathway.id : '';
    this.gaEvent.event['organisation_id'] = this.gp ? this.gp.organisation_id : '';
    if (name.includes('welcomequestionnaire')) {
      this.gaEvent.event['questionnaire_id'] = this.treatment.introquestionnaire.id ?? localStorage.getItem('welcomequestionnaire_id');
    }

    if(['/initial_screening'].indexOf(name) != -1 && this.treatment != undefined && this.treatment.introquestionnaire != undefined && this.treatment.introquestionnaire.id != undefined) {
      this.gaEvent.event['questionnaire_id'] = this.treatment.introquestionnaire.id ?? localStorage.getItem('questionnaire_id');
      if (!this.gaEvent.event['answer_id']) {
        this.gaEvent.event['answer_id'] = localStorage.getItem('questionnaire_answer_id');
      }
    }

    if(['/unlocked'].indexOf(name) != -1 && localStorage.getItem('questionnaire_answer_id')) {
      this.gaEvent.event['questionnaire_id'] = localStorage.getItem('questionnaire_id');
      this.gaEvent.event['answer_id'] = localStorage.getItem('questionnaire_answer_id');
    }

    if (this.gaEvent.event['questionnaire_id'] == undefined && this.gaEvent.event['answer_id'] == undefined) {

      if (type == 'formSubmission') {
        console.log('questionnaire_id and answer_id are undefined for event:', name);
        this.gaEvent.event['questionnaire_id'] = localStorage.getItem('questionnaire_id');
        this.gaEvent.event['answer_id'] = localStorage.getItem('questionnaire_answer_id');
      }

    }

    if (replace_params) {
      for (let i in replace_params) {
        this.gaEvent.event[i] = replace_params[i];
      }
    }
    
    setTimeout(() => {
      localStorage.removeItem('questionnaire_id');
      localStorage.removeItem('questionnaire_answer_id');
    }, 1000 * 30); // 30 seconds

    return this.apiPostPremise(this.isUserLogged ? 'userappnav' : 'usernavguest', JSON.parse(JSON.stringify(this.gaEvent)));
  }

  openMenu() {
    this.menuCtrl.open('main');

    console.log(this)
  }

  closeMenu() {
    console.log("should close menu")
    setTimeout(() => {
      this.menuCtrl.close('main');
    }, 500);
  }

  enableMenu(enable: boolean = true) {
    this.menuCtrl.enable(enable, 'main');
  }

  resetPulseTimer(interval: number = 10000) {
    if (!interval)
      clearInterval(this.timer)
    else {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.pulse();
      }, interval)
    }
  }
  apiPost(url: string, payload: any) {
    return this.http.post(url, payload)
  }

  /**
   * Make a POST request to the API (url is automatically prefixed with the base path and transformed to kebab-case)
   * Token and pathway_id are added to the payload automatically
   */
  postApiRequest(url: string, payload: any = false) {
    if (!payload) {
      payload = {};
    }
    return this.apiPostPremise(this.caseTransform(url), payload);
  }

  httpPost(url: string, data: any = false) {

    let payload = !data ? {} : data;
    payload['token'] = payload['token'] == undefined ? this.token : payload['token'];
    payload['pathway_id'] = this.pathway ? this.pathway.id : null;

    let apiUrl = this.basePath + 'api/' + this.caseTransform(url);
    
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          console.log('next post-promise URL: ', apiUrl);
          resolve(response);
        },
        error: function (response) {
          reject(response);
        }
      })
    });
  }

  httpGet(url: string, data: any = false) {
    let payload = !data ? {} : data;
    payload['token'] = payload['token'] == undefined ? this.token : payload['token'];
    payload['pathway_id'] = this.pathway ? this.pathway.id : null;

    let apiUrl = this.basePath + 'api/' + this.caseTransform(url);

    console.log('next get-promise URL: ', apiUrl);

    return new Promise((resolve, reject) => {
      this.http.get(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Token': this?.token ? this.token : null,
          'Pathway': this.pathway ? this.pathway.id : null,
          'Diag': JSON.stringify(this.diag)
        }),
        params: data
      })
        .subscribe({
          next: function (response: any) {
            console.log('next get-promise URL: ', apiUrl);
            resolve(response);
          },
          error: function (response) {
            reject(response);
          }
        })
    });
      
  }

  /**
   * from PascalCase, camelCase, or snake_case to kebab-case
   */
  caseTransform(input: string, useSnakeCase: boolean = false): string {

    if (useSnakeCase) {
      return input
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2') // PascalCase or camelCase to snake_case
        .replace(/-/g, '_') // kebab-case to snake_case
        .toLowerCase();
    }
    return input
      .replace(/([a-z0-9])([A-Z])/g, '$1-$2') // PascalCase or camelCase to kebab-case
      .replace(/_/g, '-') // snake_case to kebab-case
      .toLowerCase();
  }

  isDebugHost() {
    const portNumber = parseInt(window.location.port, 10);
    const fullHost = window.location.hostname; // e.g. "sub.domain.tld" or "a.b.domain.tld"
    // Special case for Ionic serve on localhost
    if (fullHost === 'localhost' && portNumber >= 8100 && portNumber <= 8110) {
      return true;
    }
    // Allowed hosts to compare against (subdomain.domain.tld format)
    const allowedHosts = ['gap.test', 'testing.getubetter.com'];
    return allowedHosts.filter(host => fullHost.includes(host)).length > 0;
  }

  /**
 * Make a POST request to the API
 * Token and pathway_id are added to the payload automatically
 */
  apiPostPremise(url, data = {}) {
    let payload = data == undefined ? {} : data;
    payload['token'] = payload['token'] == undefined ? this.token : payload['token'];

    if (url == 'userappnav') {
      payload['webappUrl'] = this.router.url;
    }
    
    payload['pathway_id'] = this.pathway ? this.pathway.id : null;
    let isDebug = this.isDebugHost();
    return new Promise((resolve, reject) => {
      this.http.post(this.basePath + 'api/' + url, this.payloadMiddleware(data)).subscribe({
        next: function (response: any) {
          console.log('next post-promise URL: ', url);

          if (url == 'generateMFA' && response?.code) {
            if (isDebug) {
              console.log('MFA code:', response?.code ?? 'No code');
            }
          }
          resolve(response);
        },
        error: function (response) {
          reject(response);
        }
      })
    })
  }

  payloadMiddleware(payload: any = {}) {
    payload.diag = this.diag
    return payload;
  }

  test(payload: any) {
    console.log(payload)
  }

  goToPage(url) {
    this.router.navigate([url]);
  }

  async goToUpdate(title: string = "Please update the app!", subtitle: string = "You will be redirected to the store", force = false) {
    let url = null;
    let that = this;
    console.log(this);
    let urlDiag = '';
    let urlUser = '';
    try {
      urlDiag = Object.keys(that.diag).map(function (k) { return encodeURIComponent(k) + '=' + encodeURIComponent(that.diag[k]) }).join('&');
      urlUser = Object.keys(that.user).map(function (k) { return encodeURIComponent(k) + '=' + encodeURIComponent(that.user[k]) }).join('&');
    } catch (Ex) {

    }
    // if(this.diag.platform == null){
    if (this.diag.platform && this.diag.platform == "ios") {
      url = "https://app.getubetter.com/ios-app-link?type=update&" + urlDiag + "&" + urlUser;
    } else {
      if (this.diag.platform && this.diag.platform == "android")
        url = "https://app.getubetter.com/android-app-link?type=update&" + urlDiag + "&" + urlUser;
    }
    if (url) {

      let buttons = [];
      if (force == false) {
        buttons.push(
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          }
        );
      }
      buttons.push(
        {
          text: 'Update',
          handler: async () => {
            await Browser.open({ url: url });
          }
        }
      );

      const alert = await this.alertController.create({
        header: title,
        message: subtitle,
        buttons: buttons,
        backdropDismiss: !force
      });
      alert.present();

      return true;
    } else {
      return false;
    }
  }

  login(payload: any, finalizeLogin = true) {
    this.showAjaxRequestLoader = true;
    let that = this;
    payload.diag = this.diag
    payload.guest_id = this.guest_id;
    return new Promise((resolve, reject) => {
      this.http.post(this.basePath + 'api/login', this.payloadMiddleware(payload)).subscribe({
        next: (response: any) => {
          console.log('next');
          if(finalizeLogin){
            that.finalizeLogin(response);
          }
          resolve(response);
        },
        error: function (response) {
          console.log('error');
          that.token = '';
          that.user = {};
          that.showAjaxRequestLoader = false;
          // that.presentAlert(response.error.title != undefined ? response.error.title : 'Login error', '', 'OK', response.error.error);
          // console.log(arguments);
          reject(response);
        }
      })
    })
  };

  finalizeLogin(response, nhsLogin = false) {
    this.user = response.user;
    this.token = response.token;
    this.guest_id = response.user.guest_id;
    this.isUserLogged = true;
    this.resetPulseTimer(60000);
    console.log(response);
    this.showAjaxRequestLoader = false;
    localStorage.removeItem('registerobject');

    // this.loginInWithNhs(nhsLogin);
  }

  finalizeLoginAndRedirect(response) {
    this.setUserSettings([{ account_validated: 1 }]);

    this.setEventsAnalytics('Login', 'event', { product_zone: 'Account Creation' });

    if (response.user.notification != undefined) {
      this.presentAlert(response.user.notification.title, '', 'OK', response.user.notification.message);
    }

    switch (true) {
      case response.user.account_validated == '0':
        this.router.navigateByUrl('/verify/phone', { replaceUrl: true });
        break;
      case response.user.suborg_id == undefined:
        this.router.navigateByUrl('/postcode-entry' + (response.user.postcode != 'NULL' && response.user.postcode ? '/' + response.user.postcode : ''), { replaceUrl: true });
        break;
      default:
        this.router.navigate(['/accesscodes'], { replaceUrl: true });
        break;
    }
  }

  public refreshUser() {
    return this.apiPostPremise('pulse').then((response: any) => {
      if (response?.user?.length == 0) {
        this.logout();
      }else{
        this.user = response.user;
        this.isUserLogged = true;
      }
      this.showAjaxRequestLoader = false;

    }, (err) => {
      console.error('Error refreshing user data:', err);
      this.logout();
    });
  }

  public checktoken() {
    this.showAjaxRequestLoader = true;
    let that = this;
    let payload = {
      token: this.token,
    };

    this.http.post(this.basePath + 'api/pulse/', this.payloadMiddleware(payload)).subscribe({
      next: (response: any) => {

        console.log('active token');
        this.user = response.user;
        // this.token = response.token;
        // this.loading.dismiss()
        // this.accesscodes()
        this.router.navigateByUrl('/accesscodes')
        this.resetPulseTimer(60000)
        console.log(response);
        // this.initPush()

        // that.recovery = response.recovery
        // that.afterSelectPathway()    
        console.log(response);
        that.showAjaxRequestLoader = false;
      },
      error: (response) => {
        that.showAjaxRequestLoader = false;
        console.log(response)
      }
    })
  }

      

  // runRecovery(recovery:any = null){
  //   if(recovery){
  //     this.day_message = recovery.day_message
  //     this.timeline = recovery.timeline
  //     this.progress = this.progress
  //     this.gateway = this.gateway
  //   }
  // }

  registerToken(fcm_token) {
    this.showAjaxRequestLoader = true;
    let that = this;
    let payload = {
      token: this.token,
      fcm_token: fcm_token
    };

    this.http.post(this.basePath + 'api/registerfcmoken', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        console.log('registerToken');
        console.log(response);
        that.showAjaxRequestLoader = false;
      },
      error: function (response) {
        console.log('error');
        //that.presentAlert('Login error', 'Can\'t login', 'OK', 'Error: ' + response.error.error);
        console.log(response);
        that.showAjaxRequestLoader = false;
      }
    })
  }

  async initPNs() {

    // if(this.diag.platform == "android" && localStorage.getItem('notification_permission_checked') != 'yes'){
    //   const alert = await this.alertController.create({
    //     header: 'Get notifications to support your recovery',
    //     message: 'Receive personlised updates, tips and reminders by selecting \'<strong>allow notifications</strong>\' from your phone settings',
    //     buttons: [
    //       {
    //         text: 'Cancel',
    //         role: 'cancel',
    //         cssClass: 'secondary',
    //         handler: (blah) => {
    //           console.log('Confirm Cancel: blah');
    //           localStorage.setItem('notification_permission_checked','yes');
    //         }
    //       },
    //       {
    //         text: 'Go to settings',
    //         handler: () => {
    //           NativeSettings.openAndroid({
    //             option: AndroidSettings.ApplicationDetails,
    //           });
    //           localStorage.setItem('notification_permission_checked','yes');
    //         }
    //       }
    //     ],
    //     backdropDismiss: false
    //   });
    //   alert.present();
    //  }
    let self = this;
    if (this.diag.platform && this.diag.platform != "web") {
      console.log('this.pnsToken: ', this.pnsToken);
      if (this.pnsToken == undefined) {
        await PushNotifications.addListener('registration', async (token) => {
          console.info('Registration token: ', token.value);
          let realToken: any = token.value;
          if (this.diag.platform == "ios") {
            realToken = (await FCM.getToken()).token;
          }
          console.log('Push registration success, realToken: ' + realToken);
          this.pnsToken = realToken;
          this.registerToken(realToken);
        });

        await PushNotifications.addListener('registrationError', err => {
          console.error('Registration error: ', err.error);
        });

        await PushNotifications.addListener('pushNotificationReceived', notification => {
          if (
            notification.data != undefined &&
            notification.data.scope != undefined &&
            notification.data.scope == 'pft_session_reminder' &&
            self.pathway &&
            self.pathway.condition != undefined &&
            self.router.url != '/pelvic-floor-trainer' &&
            self.router.url != '/pelvic-floor-trainer/success' &&
            self.router.url != '/pelvic-floor-trainer/partial-success?long_done=false' &&
            self.router.url != '/pelvic-floor-trainer/partial-success?long_done=true'
          ) {
            self.presentAlertActions("It's time to start training", 'Here is your reminder to start your Pelvic Floor Exercise session.', [
              {
                text: 'Not now',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                  console.log('Confirm Cancel: blah');
                }
              },
              {
                text: 'Start Trainer',
                handler: async () => {
                  if (self.pathway.has_pelvic_floor_trainer == 1) {
                    self.router.navigateByUrl('/pelvic-floor-trainer/dashboard');
                  } else {
                    self.router.navigateByUrl('/accesscodes');
                  }
                }
              }
            ]);
          }
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });

        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!');
        }

        await PushNotifications.register();
      } else {
        this.registerToken(this.pnsToken);
      }
    }
  }

  doneGateway(gateway_id) {
    this.showAjaxRequestLoader = true;
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      gateway_id: gateway_id
    };

    this.http.post(this.basePath + 'api/donegateway', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        console.log('doneGateway');
        console.log(response);
        that.showAjaxRequestLoader = false;
      },
      error: function (response) {
        console.log('error');
        //that.presentAlert('Login error', 'Can\'t login', 'OK', 'Error: ' + response.error.error);
        console.log(response);
        that.showAjaxRequestLoader = false;
      }
    })

  }

  loadQuestionnaire(id) {
    this.showAjaxRequestLoader = true;
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      questionnaire_id: id
    };

    return new Promise((resolve, reject) => {
      that.http.post(that.basePath + 'api/loadquestionnaire/', this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          // that.recovery = response.recovery
          that.showAjaxRequestLoader = false;
          resolve(response);
          console.log("loadquestionnaire DONE");
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
          reject(response)
        }
      })
    });

  }

  donewelcomequestionnaire(questionnaire) {
    this.showAjaxRequestLoader = true;
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      questionnaire_id: questionnaire.id
    };

    return new Promise((resolve, reject) => {
      that.http.post(that.basePath + 'api/donewelcomequestionnaire/', this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          // that.recovery = response.recovery
          that.showAjaxRequestLoader = false;
          questionnaire = null;
          console.log("refreshrecovery DONE");
          //that.setEventsAnalytics('Initial screening','formSubmission');
          resolve(response);
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          // that.presentAlert('API Warning', ' ', 'OK', response.error.error);
          //that.setEventsAnalytics('Initial screening','formSubmissionError');
          reject(response)
        }
      })
    });
  }

  resetquestionnaire(redirect_to = '/unlocked') {
    this.showAjaxRequestLoader = true;
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id
    };

    this.http.post(this.basePath + 'api/resetquestionnaire/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.showAjaxRequestLoader = false;
        that.recovery = response.recovery;
        console.log('done reseter from resetquestionnaire');
        localStorage.setItem('questionnaire_id', response.questionnaire_id);
        localStorage.setItem('questionnaire_answer_id', response.answer_id);

        this.setEventsAnalytics('/unlocked questionnaire reset ', 'formSubmission', { questionnaire_id: response.questionnaire_id, answer_id: response.answer_id, product_zone: 'Questionnaire Reset', pathway_id: that.pathway.id });
        that.router.navigateByUrl(redirect_to);
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })
  }

  loadProgram(program_id): Promise<any> {
    this.showAjaxRequestLoader = true;
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      program_id: program_id
    };

    return new Promise((resolve, reject) => {
      that.http.post(that.basePath + 'api/loadprogram/', this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          // that.recovery = response.recovery
          that.showAjaxRequestLoader = false;
          resolve(response);
          console.log("refreshrecovery DONE");
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
          reject(response)
        }
      })
    });

  }

  loadGateway(gateway_id): Promise<any> {
    let that = this;
    this.showAjaxRequestLoader = true;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      gateway_id: gateway_id
    };

    if (this.gateway.questionnaire_type === 'feedback') {
      this.router.navigateByUrl('/home');
    }

    return new Promise((resolve, reject) => {
      that.http.post(that.basePath + 'api/loadgateway/', this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          // that.recovery = response.recovery
          that.showAjaxRequestLoader = false;
          if (response.gateway.questionnaire_type === 'feedback') {
            that.router.navigateByUrl('/home');
          }
          resolve(response);
          console.log("refreshrecovery DONE");
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
          reject(response)
        }
      })
    });

  }

  selectWorries(active) {
    this.showAjaxRequestLoader = true;
    console.log(active)
    let that = this;

    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      active: active
    };

    this.http.post(this.basePath + 'api/selectworries/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        localStorage.setItem('feedbackzone', JSON.stringify(response.feedbackzone))
        that.showAjaxRequestLoader = false;
        that.router.navigateByUrl('/monitorsummary/worries');
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })
  }

  selectProblems(active) {
    this.showAjaxRequestLoader = true;
    console.log(active)
    let that = this;

    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      active: active
    };

    this.http.post(this.basePath + 'api/selectproblems/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        localStorage.setItem('feedbackzone', JSON.stringify(response.feedbackzone))
        that.showAjaxRequestLoader = false;
        that.router.navigateByUrl('/monitorsummary/problems');
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })
  }

  changeDay(day) {
    this.showAjaxRequestLoader = true;
    let that = this;

    // this.addDaysToday(day);
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      day: day
    };

    this.http.post(this.basePath + 'api/changeday/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.recovery = response.recovery;
        that.showAjaxRequestLoader = false;
        that.presentAlert('Done', 'Recovery day changed', 'OK', '');
        that.router.navigateByUrl('/accesscodes');
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        that.presentAlert('Day change error', 'Can\'t save day', 'OK', response.error.error);
      }
    })
  }

  bookProgram(program, provider) {
    this.showAjaxRequestLoader = true;
    console.log("bookProgram")
    console.log(program)
    console.log(provider)
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      program: program,
      provider: provider
    };

    this.http.post(this.basePath + 'api/bookprogram/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.programs = response.programs;
        that.showAjaxRequestLoader = false;
        that.router.navigateByUrl('/home')
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        that.presentAlert('Onboarding error', '', 'OK', response.error.error);
      }
    })
  }

  book(product) {
    this.showAjaxRequestLoader = true;
    console.log(product)
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      product: product
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.basePath + 'api/bookappointment/', this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          that.appointments = response.appointments;
          that.letters = response.letters;
          that.showAjaxRequestLoader = false;
          resolve(response.appointments);
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          that.presentAlert(response.error.errorTitle ? response.error.errorTitle : 'Booking error', response.error.errorSubtitle ? response.error.errorSubtitle : '', 'OK', response.error.error);
          reject(response);
        }
      })
    })

  }

  async presentAlert(header: string, subHeader: string, button: string, response: string) {

    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: response,
      buttons: [button]
    });

    await alert.present();
  }

  async presentAlertActions(header: string, subHeader: string, buttons, response: string = '') {

    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: response,
      buttons: buttons,
      backdropDismiss: false
    });

    await alert.present();
  }

  async presentAlertActionsInputs(header: string, subHeader: string, buttons, inputs, response: string = '') {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: response,
      buttons: buttons,
      inputs: inputs,
      backdropDismiss: false
    });

    await alert.present();
  }

  async nhsNumberPopups() {
    console.log('this.hideNhsNumberPopup: ', this.hideNhsNumberPopup);
    if (this.hideNhsNumberPopup == 1) return;
    if (this.user.nhs_status == 'not_found_3_times_inserted') {
      this.presentAlertActions('NHS number not found.', 'Your NHS number could not be found. This means that we cannot update your GP you are using the app.  This will not affect your healthcare.', [{
        text: 'Ok',
        handler: (data) => {
          this.hideNhsNumberPopup = 1;
        }
      }]);

      return;
    }

    if (this.user.nhs_action != undefined) {
      if (this.user.nhs_status != undefined) {
        if (this.user.nhs_status != 'not_found') {
          return;
        }
      }
      let that = this;
      let buttons = [
        {
          text: 'Not now',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.hideNhsNumberPopup = 1;
            this.setUserSettings([
              {
                not_now_nhs_popup: '1',
                not_now_nhs_popup_date: moment().format('YYYY-MM-DD H:mm:ss')
              }
            ]);
            this.presentAlert('NHS Number postponed.', 'You can always go to your profile and insert your NHS Number.', 'OK', '');
          }
        },
        {
          text: 'Enter NHS #',
          handler: async () => {
            let buttons2 = [
              {
                text: 'Not now',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                  this.hideNhsNumberPopup = 1;
                  this.setUserSettings({
                    not_now_nhs_popup: '1',
                    not_now_nhs_popup_date: moment().format('YYYY-MM-DD H:mm:ss')
                  });
                  this.presentAlert('NHS Number postponed.', 'You can always go to your profile and insert your NHS Number.', 'OK', '');
                }
              },
              {
                text: 'Save',
                handler: (data) => {
                  this.setNHSNumber({ temporary_nhs_number: data.temporary_nhs_number });
                }
              }
            ];

            const alertInput = await this.alertController.create({
              header: 'NHS Number',
              subHeader: 'Please type your NHS Number',
              message: '',
              buttons: buttons2,
              inputs: [
                {
                  name: 'temporary_nhs_number',
                  type: 'text'
                }
              ],
              backdropDismiss: false
            });

            await alertInput.present();
          }
        }
      ];
      if (this.user.temporary_nhs_number != undefined && this.user.temporary_nhs_number) {
        this.presentAlertActions('NHS number not found.', 'Your NHS Number ' + this.user.temporary_nhs_number + ' could not be found. Add your NHS number to update your GP', buttons);
      } else {
        this.presentAlertActions('NHS number not found.', '', buttons, 'Your NHS Number based on your name, date of birth and postcode was not found. Add your NHS number to update your GP. <br />If you don\'t know your NHS number, please contact your GP Practice.');
      }
    }
  }

  isLogged() {
    return !!this.token;
  }

  getMenu() {
    return this.mainMenu;
  }

  logMessage(message) {
    // this.http.post(this.basePath + 'api/logmessage', { message: message }).subscribe({
    //   next: function (response: any) {
    //     console.log('logmessage');
    //     console.log(response);
    //   },
    //   error: function (response) {
    //     console.log('error');
    //     console.log(response);
    //   }
    // })
  }

  updateAsymptomaticPathwayProgress() {
    let that = this;
    return new Promise(async (resolve, reject) => {
      let progress: any;
      switch (true) {
        case that.pathway.endpoints.improvingendpoints == true:
          progress = await that.updateProgress({
            title: "improving",
            id: 3
          });
          break;
        case that.pathway.endpoints.thesameendpoints == true:
          progress = await that.updateProgress({
            title: "same",
            id: 2
          });
          break;
        case that.pathway.endpoints.worseendpoints == true:
          progress = await that.updateProgress({
            title: "worse",
            id: 1
          });
          break;
        case that.pathway.endpoints.betterendpoints == true:
          progress = await that.updateProgress({
            title: "better",
            id: 4
          });
          break;
      }

      resolve(progress);
    })
  }

  afterSelectPathway(response) {
    let that = this;
    console.log('this.pathway: ', this.pathway);
    that.recovery = response.recovery;
    console.log('that.day: ', that.day);
    let user_pathways = JSON.parse(localStorage.getItem('user_pathways'));

    if (user_pathways && user_pathways.length == 0) {
      this.setEventsAnalytics('Condition Pathway Added', 'event', { product_zone: 'Screening', pathway_name: this.pathway.condition.name });
    } else {
      
      if (user_pathways && user_pathways.indexOf(this.pathway.id) == -1) {
        this.setEventsAnalytics('Condition Second Pathway Added', 'event', { product_zone: 'Screening', pathway_name: this.pathway.condition.name });
      }
    }
    localStorage.removeItem('user_pathways');

    if (response.smodules != undefined && response.smodules == true) {
      that.pullAppContent(['smodules']).then((response) => {
        let skip = localStorage.getItem('skip_smodules');
        if (that.availablesmodules && that.availablesmodules.length > 0 && !skip) {
          that.router.navigateByUrl('/support-modules');
        } else {
          that.router.navigateByUrl('/splash');
        }
      });
    } else {
      this.router.navigateByUrl('/splash');
    }
  }

  reinjured() {
    console.log('reinjured called !!!!!!');
    this.showAjaxRequestLoader = true;
    let that = this;

    let payload = {
      token: this.token,
      pathway: this.pathway.id
    };


    this.http.post(this.basePath + 'api/reinjured/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.recovery = response.recovery;
        that.endpoint = response.endpoint;
        that.showAjaxRequestLoader = false;
        if (response.recovery && response.recovery.day == false)
          that.router.navigateByUrl('/welcomequestions', { replaceUrl: true })
        else
          that.router.navigateByUrl('/welcomequestionnaire', { replaceUrl: true })
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        that.presentAlert('Date set error', 'Can\'t set the date your problem started ', 'OK', response.error.error);
      }
    })
  }

  switchPathway(p: any, related: any) {
    this.showAjaxRequestLoader = true;
    let that = this;
    let payload = {
      token: this.token,
      pathway: p,
      options: related.options,
      related: related.related
    };

    this.http.post(this.basePath + 'api/switchpathway/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.showAjaxRequestLoader = false;
        if (response.pathway)
          that.pathway = response.pathway;
        that.afterSelectPathway(response);
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        this.token = '';
        this.user = {};
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })
  }

  async selectPathway(p: any) {
    this.showAjaxRequestLoader = true;
    let that = this;

    let payload = {
      token: this.token,
      pathway: p.id,
      suborg_id: p.pivot ? p.pivot.suborg_id : null
    };

    this.http.post(this.basePath + 'api/selectpathway/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.pathway = p;
        that.showAjaxRequestLoader = false;
        that.afterSelectPathway(response);
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })
  }

  updateUserTTSLanguage(language_id) {
    this.showAjaxRequestLoader = true;
    let self = this;
    let payload = {
      token: this.token,
      language_id: language_id
    };

    return new Promise((resolve, reject) => {
      self.http.post(self.basePath + 'api/updateuserttslanguage/', self.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          self.showAjaxRequestLoader = false;
          let user = self.user;
          user.language_id = language_id;
          self.user = user;
          resolve(response);
        },
        error: function (response) {
          self.showAjaxRequestLoader = false;
          self.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
          reject(response)
        }
      })
    })
  }

  testToSpeach(text): Promise<any> {
    this.showAjaxRequestLoader = true;
    let self = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      text: text,
      language_id: this.user.language_id
    };

    return new Promise((resolve, reject) => {
      self.http.post(self.basePath + 'api/getspeachbytext/', self.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          self.showAjaxRequestLoader = false;
          resolve(response);
        },
        error: function (response) {
          self.showAjaxRequestLoader = false;
          self.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
          reject(response)
        }
      })
    })
  }

  refreshRecovery(): Promise<any> {
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id
    };

    return new Promise((resolve, reject) => {
      that.http.post(that.basePath + 'api/refreshrecovery/', that.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          that.recovery = response.recovery;
          resolve(response);
          console.log("refreshrecovery DONE");
        },
        error: function (response) {
          if (response.error.title != undefined && response.error.title == 'Update available') {
            that.goToUpdate(response.error.title, response.error.subtitle);
          } else {
            that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
          }
          reject(response);
        }
      })
    });

  }

  pulse() {
    let that = this;
    if (!this.pathway) {
      this.resetPulseTimer(0)
      return false;
    }

    console.log('run pulse')
    console.log(this.diagnostic)

    let payload = {
      token: this.token,
      data: this.diagnostic,
      pathway: this.pathway ? this.pathway.id : null
    };

    this.http.post(this.basePath + 'api/pulse/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        // that.recovery = response.recovery
        // that.afterSelectPathway()     
        console.log(response)
        console.log(that.router)
        console.log("^ router")
      },
      error: (response) => {
        that.showAjaxRequestLoader = false;
        if (response.status == 401) {
          that.resetPulseTimer(0);
          if (that.router.url !== '/guest')
            that.logout();
        }

        if (response.status == 409) {
          // that.presentAlert('Painscore error', 'API request error', 'OK', response.error.error);
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.')
        }
        console.log(response)
      }
    })
    return null;
  }

  async updatePainscore(painscore: number) {
    this.showAjaxRequestLoader = true;
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      painscore: painscore
    };

    const response: any = await this.http.post(this.basePath + 'api/selectpainscore/', this.payloadMiddleware(payload))
      .toPromise()
      .catch(function (response) {
        that.showAjaxRequestLoader = false;
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
        return false;
      })
    that.showAjaxRequestLoader = false;

    return response;
  }

  selectPainscore(painscore: number) {
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      painscore: painscore
    };
    that.showAjaxRequestLoader = true;
    this.http.post(this.basePath + 'api/selectpainscore/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.painscore = painscore;
        that.showAjaxRequestLoader = false;
        that.recovery.painhistory = response.painhistory;
      },
      error: function (response) {
        this.token = '';
        this.user = {};
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })
  }

  aimFeedback(feedback: number) {
    this.currentAimFeedback = feedback;
    let that = this
    let payload: any = {};
    payload.feedback = feedback;
    payload.token = this.token;
    payload.pathway_id = this.pathway.id;
    payload.pathway = this.pathway.id;

    this.http.post(this.basePath + 'api/aimfeedback/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.recovery = response.recovery
        // that.presentAlert('Thanks for sending your feedback', '', 'OK', 'You can view your aim in "My Diary"');
        // that.router.navigateByUrl('/feedbackzone')
      },
      error: function (response) {
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })

    // this.diaryUpdated.emit("changed")
  }

  setAim(payload: any = {}) {
    let that = this
    payload.token = this.token;
    payload.pathway_id = this.pathway.id;
    payload.pathway = this.pathway.id;

    this.http.post(this.basePath + 'api/aimcreate/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.diary = response.diary;
        that.aim = response.aim;
        that.presentAlert('Thanks for setting an aim', '', 'OK', 'We’ve added it to Your diary');
        that.router.navigateByUrl('/feedbackzone')
      },
      error: function (response) {
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })

    // this.diaryUpdated.emit("changed")
  }

  async diarycreate(note: number, id = false) {
    let that = this;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      note: note,
      id: id
    };

    const response: any = await this.http.post(this.basePath + 'api/diarycreate/', this.payloadMiddleware(payload))
      .toPromise()
      .catch(function (response) {
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
        return false;
      })
    that.diary = response.diary;
    this.diaryUpdated.emit("changed")

    return response;
  }

  async questionnaire(questionnaire: any) {
    let that = this;
    that.showAjaxRequestLoader = true;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      questionnaire: questionnaire
    };

    const response = await this.http.post(this.basePath + 'api/questionnaire/', this.payloadMiddleware(payload))
      .toPromise()
      .catch(function (response) {
        that.showAjaxRequestLoader = false;
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
        return false;
      })
    that.showAjaxRequestLoader = false;
    that.pullAppContent(['letters']);
    return response;
  }

  selectWelcome(welcome: any, callback: any = null) {
    let that = this;
    that.showAjaxRequestLoader = true;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      welcome: welcome
    };

    this.http.post(this.basePath + 'api/selectwelcome/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.recovery = response.recovery
        //check intro questionnaire
        that.pullAppContent();
        if (that.pathway.show_traffic_light_page == 0 && (that.progress == undefined || that.progress.code == undefined)) {
          that.showAjaxRequestLoader = false;
          that.updateAsymptomaticPathwayProgress().then((x) => {
            try {
              console.log('reset welcome');
              console.log(welcome);
              welcome = {
                injured: null,
                injured2nd: null,
                recent_flare: null,
                recurrence: false,
                visited: [false, false, false, false, false, false, false, false]
              };
              // nhs-callback.call()
            } catch (Ex) {
              console.log(Ex)
            }
            if (that.treatment && that.treatment.introquestionnaire) {
              that.router.navigateByUrl('/welcomequestionnaire')
            }
            else {
              that.router.navigateByUrl('/recoverytimeline')
            }
          })
        } else {
          try {
            console.log('reset welcome');
            console.log(welcome);
            welcome = {
              injured: null,
              injured2nd: null,
              recent_flare: null,
              recurrence: false,
              visited: [false, false, false, false, false, false, false, false]
            };
            // nhs-callback.call()
          } catch (Ex) {
            console.log(Ex)
          }
          if (that.treatment && that.treatment.introquestionnaire) {
            that.router.navigateByUrl('/welcomequestionnaire')
          }
          else {
            that.router.navigateByUrl('/recoverytimeline')
          }
        }
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        this.token = '';
        this.user = {};
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })
  }


  quickWelcome(welcome: any, callback: any = null, callback2nd: any = null, callback3nd: any = null) {
    let that = this;
    if (welcome.injured && typeof welcome.injured == 'object') {
      let injury_date = new Date(welcome.injured.toISOString().split('T')[0]);
      welcome.injured = welcome.injured ? new Date(injury_date.setDate(injury_date.getDate() + 1)).toISOString().split('T')[0] : welcome.injured;
    }
    if (welcome.injured2nd && typeof welcome.injured2nd == 'object') {
      let injured2nd_date = new Date(welcome.injured2nd.toISOString().split('T')[0]);
      welcome.injured2nd = welcome.injured2nd ? new Date(injured2nd_date.setDate(injured2nd_date.getDate() + 1)).toISOString().split('T')[0] : welcome.injured2nd;
    }
    that.showAjaxRequestLoader = true;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      welcome: welcome
    };

    this.http.post(this.basePath + 'api/selectwelcome/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.showAjaxRequestLoader = false;
        that.recovery = response.recovery;
        that.pullAppContent();
        if (that.pathway.show_traffic_light_page == 0 && (that.progress == undefined || that.progress.code == undefined)) {
          that.updateAsymptomaticPathwayProgress().then((x) => {
            callback.call();
          })
        } else {
          callback.call();
        }
        //check intro questionnaire
        // try{
        //   console.log('reset welcome');
        //   console.log(welcome);
        //   welcome = {
        //     injured: null,
        //     recurrence: false,
        //     visited: [false, false, false, false, false, false, false, false]
        //   };

        // }catch(Ex){
        //   console.log(Ex)
        // }
        // if(that.treatment && that.treatment.introquestionnaire){
        //   that.router.navigateByUrl('/welcomequestionnaire')
        // }
        // else
        //   that.router.navigateByUrl('/recoverytimeline')
      },
      error: function (response) {
        // this.token = '';
        // this.user = {};
        that.showAjaxRequestLoader = false;
        console.log(response);
        if (response.status == 555)
          callback2nd.call();
        else
          if (response.status != 503) {
            that.presentAlert('Warning', '', 'OK', response.error.error);
            callback3nd.call();
          }
      }
    })
  }

  signup(payload) {
    let that = this;
    that.showAjaxRequestLoader = true;
    payload['phone'] = payload['phone'].replace('+440', '+44');
    return new Promise((resolve, reject) => {
      this.http.post(this.basePath + 'api/signup/', this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          that.gp = response.gp;
          that.showAjaxRequestLoader = false;
          that.setEventsAnalytics('Register', 'formSubmission', { currentpage: '/register', product_zone: 'Account Creation' });
          resolve(response);
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          this.token = '';
          this.user = {};
          // that.presentAlert(errorTitle, '', 'OK', response.error.error);
          reject(response)
        }
      })
    })

  }

  accesscodes(showLoader = true, redirect = true) {
    let that = this;
    that.showAjaxRequestLoader = showLoader;
    let payload = {
      token: this.token
    };

    return new Promise((resolve, reject) => {
      this.http.post(this.basePath + 'api/accesscodes/', this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          that.showAjaxRequestLoader = false;
          that.pathways = response.pathways;
          localStorage.setItem('user_pathways', JSON.stringify(response.user_pathways));
          if (redirect == true) {
            that.router.navigateByUrl('/accesscodes');
          }
          resolve(response);
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          if (response.status != 503)
            that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
          reject(response);
        }
      })
    })

  }

  updateProfile(payload: any) {
    let that = this;
    that.showAjaxRequestLoader = true;
    payload.token = this.token;
    payload.pathway = this.pathway.id;

    return new Promise((resolve, reject) => {
      this.http.post(this.basePath + 'api/updaterofile/', this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          that.showAjaxRequestLoader = false;          
          resolve(response);
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          if (response.status != 503 && response.status != 400)
            that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
          else reject(response);
        }
      })
    })
  }

  setNHSNumber(payload: any) {
    let that = this;
    that.showAjaxRequestLoader = true;
    payload.token = this.token;

    this.http.post(this.basePath + 'api/setnhsnumber/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.showAjaxRequestLoader = false;
        that.user = response.user;
        that.presentAlert('NHS number status', 'Your NHS number is being proccessed.', 'OK', '');
        that.router.navigateByUrl('/home');
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        if (response.status != 503)
          that.presentAlert('NHS number error', 'Can\'t update information', 'OK', response.error.error);
      }
    })
  }


  removeLetter(payload: any) {
    let that = this;
    that.showAjaxRequestLoader = true;
    payload.token = this.token;
    payload.pathway = this.pathway.id;

    this.http.post(this.basePath + 'api/removeletter/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.showAjaxRequestLoader = false;
        that.letters = response.letters
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        if (response.status != 503)
          that.presentAlert('Inbox warning', 'Can\'t remove letter', 'OK', response.error.error);
      }
    })
  }

  forgot(email) {
    let that = this;
    that.showAjaxRequestLoader = true;
    let payload = {
      email: email
    };

    return new Promise((resolve, reject) => {
      this.http.post(this.basePath + 'api/forgot/', this.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          that.showAjaxRequestLoader = false;
          resolve(true);
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          reject(response);
        }
      })
    })

  }

  readLetter(payload: any) {
    let that = this;
    payload.token = this.token;
    payload.pathway = this.pathway.id;

    this.http.post(this.basePath + 'api/readletter/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.showAjaxRequestLoader = false;
        that.letters = response.letters;
      },
      error: function (response) {
        // that.presentAlert('Inbox warning', 'Can\'t remove letter', 'OK', response.error.error);
      }
    })
    console.log('reading')
    console.log(payload)
  }

  updatePassword(payload: any) {
    let that = this;
    that.showAjaxRequestLoader = true;
    payload.token = this.token;
    payload.pathway = this.pathway.id;

    this.http.post(this.basePath + 'api/updatepassword/', this.payloadMiddleware(payload)).subscribe({
      next: function (response: any) {
        that.showAjaxRequestLoader = false;
        //that.recovery = response.recovery;
        that.user = response.user;
        that.presentAlert('Password updated', '', 'OK', '');
        that.router.navigateByUrl('/home')
      },
      error: function (response) {
        that.showAjaxRequestLoader = false;
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      }
    })
  }

  async uploadDiaryData(formData: FormData) {

    let that = this
    const loading = await this.loadingController.create({
      message: 'Uploading diary image...',
    });
    await loading.present();

    // this.uploadPhoto(formData);
    formData.append('token', this.token);
    formData.append('pathway', this.pathway.id);

    this.http.post(this.basePath + "api/diarycreate", formData)
      .pipe(
        finalize(() => {
          loading.dismiss();
          this.diaryUpdated.emit("changed")
        })
      )
      .subscribe(res => {
        console.log(res)
        if (res['message'] == "Done") {
          // this.user = res.user
          this.diary = res['diary']
          this.router.navigateByUrl('/diary/' + res['note']['id'])
          this.diaryUpdated.emit("changed")

          this.presentToast('File upload complete.')
        } else {
          this.presentToast('File upload failed.')
        }
      });
  }

  async uploadImageData(formData: FormData) {
    let that = this
    const loading = await this.loadingController.create({
      message: 'Uploading image...',
    });
    await loading.present();

    // this.uploadPhoto(formData);
    formData.append('token', this.token);
    formData.append('pathway', this.pathway.id);

    this.http.post(this.basePath + "api/upload", formData)
      .pipe(
        finalize(() => {
          loading.dismiss();
        })
      )
      .subscribe(res => {
        console.log(res)
        if (res['message'] == 'Done') {
          // this.user = res.user
          this.user = res['user']
          this.presentToast('File upload complete.')
        } else {
          this.presentToast('File upload failed.')
        }
      });
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000
    });
    toast.present();
  }

  async exerciselevel(exercise: any = null, level: any = null) {
    let that = this;
    that.showAjaxRequestLoader = true;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      exercise: exercise,
      level: level
    };

    const ret: any = await this.http.post(this.basePath + 'api/exerciselevel', this.payloadMiddleware(payload))
      .toPromise()
      .catch(function (response) {
        that.showAjaxRequestLoader = false;
        if (response.status != 503)
          that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
        return false;
      })
    that.showAjaxRequestLoader = false;
    this.endpoint = ret.endpoint;
    this.availablesmodules = ret.smodules.availablesmodules;
    this.smodules = ret.smodules.smodules;
    this.pftsmodules = ret.pftsmodules;
    return ret;

  }

  labels(input: String, section: any) {
    return this.pathwaylabel(input, section);
  }

  get visitedsmpages(): any {
    try {
      let ret = JSON.parse(localStorage.getItem('visitedsmpages'));
      if (ret)
        return ret;
      else
        return []
    } catch (Ex) {
      return [];
    }
  }

  set visitedsmpages(visitedsmpages: any) {
    let done = localStorage.setItem('visitedsmpages', JSON.stringify(visitedsmpages));
  }

  get visitedboxes(): any {
    try {
      let ret = JSON.parse(localStorage.getItem('visitedboxes'));
      if (ret)
        return ret;
      else
        return {}
    } catch (Ex) {
      return {};
    }
  }

  set visitedboxes(visitedboxes: any) {
    let done = localStorage.setItem('visitedboxes', JSON.stringify(visitedboxes));
  }

  async visitbox(module: any = {}, box: any, page: any = {}) {
    let that = this;
    this.showAjaxRequestLoader = true;
    let visitedboxes = this.visitedboxes;
    if (!visitedboxes[module]) {
      visitedboxes[module] = [];
    }
    if (visitedboxes[module].indexOf(box) == -1) {
      visitedboxes[module].push(box);
      this.visitedboxes = visitedboxes;
    }

    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      module_id: module,
      box_id: box,
      visitedboxes: this.visitedboxes,
    };

    const ret = await this.http.post(this.basePath + 'api/boxvisit', this.payloadMiddleware(payload))
      .toPromise()
      .catch((response) => {
        that.showAjaxRequestLoader = false;
        // this.presentAlert('Exercise error', 'Can\'t save exercise level', 'OK', response.error.error);
        return false;
      })
    that.showAjaxRequestLoader = false;
  }

  ismoduleVisited(smodule: any) {
    let ret = this.visitedsmpages.find((item) => item.smpage_id && smodule.pages && smodule.pages.length && smodule.pages[0] && smodule.pages[0].id == item.smpage_id);
    return ret;
  }

  ispageVisited(smpage_id: any) {
    let ret = this.visitedsmpages.find((item) => item.smpage_id == smpage_id);
    return ret;
  }

  isboxVisited(box: any, module: any) {
    if (this.visitedboxes[module] && this.visitedboxes[module].indexOf(box) > -1)
      return true;
    else
      return false;
  }

  get smodules(): any {
    try {
      let ret = JSON.parse(localStorage.getItem('smodules'));
      if (ret)
        return ret;
      else
        return []
    } catch (Ex) {
      return [];
    }
  }

  set smodules(smodules: any) {
    let done = localStorage.setItem('smodules', JSON.stringify(smodules));
  }

  get availablesmodules(): any {
    try {
      let ret = JSON.parse(localStorage.getItem('availablesmodules'));
      if (ret)
        return ret;
      else
        return []
    } catch (Ex) {
      return [];
    }
  }

  get routerHistory() {
    let ret = JSON.parse(localStorage.getItem('router_history'));
    return ret ? ret : [];
  }

  set routerHistory(url) {
    let ret = JSON.parse(localStorage.getItem('router_history'));
    if (!ret) {
      ret = [];
    }
    if (ret.length > 5) {
      ret.shift();
    }
    ret.push(url);
    localStorage.setItem('router_history', JSON.stringify(ret));
  }

  set availablesmodules(availablesmodules: any) {
    let done = localStorage.setItem('availablesmodules', JSON.stringify(availablesmodules));
  }

  public listingLog(payload: any = {}) {

    let that = this;
    payload.token = this.token;
    payload.pathway = this.pathway.id;
    
    this.http.post(this.basePath + 'api/pulse/', this.payloadMiddleware(payload)).subscribe({
      next: (response: any) => {

        console.log('active token');
        this.user = response.user;
        // this.token = response.token;
        // this.loading.dismiss()
        // this.accesscodes()
        // this.router.navigateByUrl('/accesscodes')
        // this.resetPulseTimer(60000)
        console.log(response);
        // this.initPush()

        // that.recovery = response.recovery
        // that.afterSelectPathway()     
        console.log(response)
      },
      error: (response) => {
        that.showAjaxRequestLoader = false;
        console.log(response)
      }
    })
  }

  bookExternal(listing: any) {
    console.log(listing);
    let that = this;
    that.showAjaxRequestLoader = true;
    let payload = {
      token: this.token,
      pathway: this.pathway.id,
      listing_id: listing.id
    };
    payload.token = this.token;
    this.http.post(this.basePath + 'api/externalbook/', this.payloadMiddleware(payload)).subscribe({
      next: async (response: any) => {
        that.showAjaxRequestLoader = false;
        console.log('active token');
        // this.user = response.user;
        // this.token = response.token;
        // this.loading.dismiss()
        // this.accesscodes()
        // this.router.navigateByUrl('/accesscodes')
        // this.resetPulseTimer(60000)
        if (response.link) {
          await Browser.open({ url: response.link });
        }
        // this.initPush()

        // that.recovery = response.recovery
        // that.afterSelectPathway()     
        console.log(response)
      },
      error: (response) => {
        that.showAjaxRequestLoader = false;
        console.log(response)
      }
    })
  }

  // get logPayload() {
  //   let message = localStorage.getItem('logPayloadMessage');
  //   if (message) {
  //     return JSON.parse(message);
  //   }

  //   return 'User has not logged anything yet';
  // }

  // set logPayload(message: string|Object) {

  //   let payload = {
  //     token: this.token,
  //     pathway: this?.pathway?.id,
  //     diag: this?.diag,
  //     message: message
  //   };

  //   localStorage.setItem('logPayloadMessage', JSON.stringify(payload));
  // }

  get pftsmodules() {
    return JSON.parse(localStorage.getItem('pftsmodules')) ?? [];
  }

  set pftsmodules(smodules) {
    localStorage.setItem('pftsmodules', JSON.stringify(smodules));
  }

  get timelineIntervals() {
    try {
      return JSON.parse(localStorage.getItem('timelineIntervals'));
    } catch (err) { return [] };
  }

  set timelineIntervals(timelineIntervals) {
    let self = this;
    localStorage.setItem('timelineIntervals', JSON.stringify(timelineIntervals ? timelineIntervals : []));
    let timelineStorage = null;

    if (timelineIntervals && timelineIntervals.length) {
      if (this.pathway.show_traffic_light_page == 0) {
        timelineStorage = timelineIntervals[0];
        timelineStorage['pos'] = 0;
      } else {
        timelineIntervals.forEach((e, i) => {
          if (e.start_day <= self.day && e.end_day >= self.day) {
            timelineStorage = e;
            timelineStorage['pos'] = ++i;
          }
        });
        if (timelineStorage == null) {
          timelineStorage = timelineIntervals[timelineIntervals.length - 1];
          timelineStorage['pos'] = timelineIntervals.length;
        }
      }
    }
    console.log('active_timeline: ', timelineStorage);
    console.log('for day: ', this.day);
    localStorage.setItem('active_timeline', JSON.stringify(timelineStorage));
  }

  pullAppContent(requests = []) {
    let that = this;
    return new Promise((resolve, reject) => {
      let promises = [];
      let setupPathways = false;
      if (requests == undefined || requests.length == 0) {
        requests = ['gp', 'endpoint', 'infozones', 'helpsections', 'boxes', 'feedbackzone', 'services', 'appointments', 'letters', 'menu', 'colors', 'programs', 'smodules', 'programproviders', 'visitedsmpages', 'visitedboxes', 'painhistory', 'diary'];
        if (that.disable_touch_to_speak == '0') {
          requests.push('userttslanguages');
        }
        if (that.pathway != undefined && that.pathway.condition != undefined && that.pathway.has_pelvic_floor_trainer == 1) {
          requests.push('pftsettings');
          requests.push('pftsmodules');
        }
        setupPathways = true;
      }
      for (let request of requests) {
        promises.push(this.apiPostPremise(request))
      }
      Promise.all(promises).then((responses) => {
        for (let pos in responses) {
          switch (true) {
            case requests[pos] == 'smodules':
              localStorage.setItem('smodules', JSON.stringify(responses[pos]['smodules']));
              localStorage.setItem('availablesmodules', JSON.stringify(responses[pos]['availablesmodules']));
              break;
            case requests[pos] == 'pftsettings':
              if (responses[pos].pftsettings) {
                responses[pos].pftsettings.reminder_1 = responses[pos].pftsettings.reminder_1 ? responses[pos].pftsettings.reminder_1.replace('.', ':') : null;
                responses[pos].pftsettings.reminder_2 = responses[pos].pftsettings.reminder_2 ? responses[pos].pftsettings.reminder_2.replace('.', ':') : null;
                responses[pos].pftsettings.reminder_3 = responses[pos].pftsettings.reminder_3 ? responses[pos].pftsettings.reminder_3.replace('.', ':') : null;
                localStorage.setItem(requests[pos], JSON.stringify(responses[pos][requests[pos]]));
              }
              break;
            default:
              localStorage.setItem(requests[pos], JSON.stringify(responses[pos][requests[pos]]));
              break;
          }
        }
        if (setupPathways == true) {
          that.setpathwaylabels();
        }
        resolve(true);
      }, err => {
        that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again later.');
      });
    })

  }

  public async rateApp() {
    // If app store rating is disabled, do nothing
    if (this.enable_app_store_rating == '0') return;
    if (this.enable_app_store_rating == '2' && this.user.debug != 1) return;
    if (this.user.app_store_rate_date != null) return;
  
    // Check if we've hit the 'Not now' limit (3+ times)
    const notNowCount = parseInt(localStorage.getItem('rate_app_not_now_count') || '0', 10);
    if (notNowCount >= 3) {
      return; // don't show the pop-up again
    }
  
    // Check if 48 hours have passed since 'last shown'
    const now = Date.now();
    const lastShown = parseInt(localStorage.getItem('rate_app_last_shown') || '0', 10);
    const hoursSinceLast = (now - lastShown) / (1000 * 60 * 60);
    if (lastShown && hoursSinceLast < 48) {
      return; // not yet time to show again
    }
  
    // Record displaying the pop-up now
    localStorage.setItem('rate_app_last_shown', now.toString());
  
    // Everything else stays as in your current pop-up code:
    // (Only the ‘Not now’ branch changes to increment the 'Not now' counter.)
    let self = this;
    let alert: any;
    let alert2: any;
    let buttons = [];
  
    buttons.push({
      text: 'No',
      handler: async () => {
        alert2 = await this.alertController.create({
          header: '',
          message: '<img src="/assets/icon/icon.png" /></br /><h3>We’re sorry to hear that</h3><p>Please help us to improve by leaving feedback</p>',
          cssClass: 'store-rating-no',
          inputs: [{ type: 'textarea', placeholder: 'Type here' }],
          buttons: [
            {
              text: 'Not now',
              handler: () => {
                // Increment 'Not now' count
                const currentCount = parseInt(localStorage.getItem('rate_app_not_now_count') || '0', 10);
                localStorage.setItem('rate_app_not_now_count', (currentCount + 1).toString());
                // Track the time so next pop-up can happen at least 48h later
                localStorage.setItem('rate_app_last_shown', Date.now().toString());

                let now_now = JSON.parse(localStorage.getItem('rate_app_not_now')) ?? [];
                now_now.push(new Date());
                localStorage.setItem('rate_app_not_now', JSON.stringify(now_now));
              }
            },
            {
              text: 'Submit',
              handler: (data) => {
                self.setUserSettings([
                  {
                    app_store_rate_like: 'no',
                    app_store_rate_date: moment().format('YYYY-MM-DD H:mm:ss'),
                    app_store_rate_review: data[0],
                    app_store_rate_screen: self.router.url
                  }
                ]).then(() => {
                  self.presentAlert('Thank you', 'Thank you for your feedback.', 'OK', '');
                });
              }
            }
          ],
          backdropDismiss: false
        });
        alert2.present();
      }
    });
  
    buttons.push({
      text: 'Yes',
      handler: () => {
        self.setUserSettings([
          {
            app_store_rate_like: 'yes',
            app_store_rate_date: moment().format('YYYY-MM-DD H:mm:ss'),
            app_store_rate_review: '',
            app_store_rate_screen: self.router.url
          }
        ]);
        InAppReview.requestReview();
      }
    });
  
    alert = await this.alertController.create({
      header: "",
      message: '<h2>Are you enjoying<br />getUBetter?</h2>',
      buttons: buttons,
      backdropDismiss: false,
      cssClass: 'store-rating-no'
    });
    alert.present();
  }

  setUserSettings(settings: any) {
    let that = this;
    that.showAjaxRequestLoader = true;

    return new Promise((resolve, reject) => {
      let payload = {
        token: that.token,
        settings: settings
      }
      that.http.post(that.basePath + 'api/setusersettings/', that.payloadMiddleware(payload)).subscribe({
        next: function (response: any) {
          that.showAjaxRequestLoader = false;
          that.user = response.user;
          resolve(true);
        },
        error: function (response) {
          that.showAjaxRequestLoader = false;
          if (response.status != 503)
            that.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again.');
          reject(response);
        }
      })
    })
  }

  public logout(goToGuest = true) {
    // Clear rate-app counters and timestamps on logout
    localStorage.removeItem('rate_app_not_now_count');
    localStorage.removeItem('rate_app_last_shown');
  
    // ...existing logout logic...
    Object.keys(localStorage).forEach(data => {
      if (['first_app_open', 'introslider', 'router_history', 'basePath', 'loginResponse', 'token'].indexOf(data) == -1) {
        localStorage.removeItem(data);
      }
    });
  
    this.isUserLogged = false;
    this.resetPulseTimer(0)
    this.enableMenu(false);
    if (goToGuest){
      this.router.navigateByUrl('/guest', { replaceUrl: true });
    }
  }

  set visitedNhsSettingsPage(set: boolean|string) {
    // DataService.visitedNhsStPage = set;
    
    DataService.visited_nhs_settings = set;
    // this.storage.set('visited_nhs_settings', set);\
    if (typeof set == 'boolean') {
      localStorage.setItem("going-to-nhs-settings", set.toString());
    } else {
      localStorage.setItem("going-to-nhs-settings", set);
    }
  }

  get visitedNhsSettingsPage() {
    let val = localStorage.getItem("going-to-nhs-settings");
    
    if (val == null){
      val = DataService.visited_nhs_settings || false;
    }
    // val = DataService.visited_nhs_settings;
    // if (val == null) {
    //   val = DataService.visitedNhsStPage;
    //   if (val == null) {
    //     val = localStorage.getItem("going-to-nhs-settings") == 'true';
    //     DataService.visitedNhsStPage = val;
    //   }
    // }
    if (typeof val == 'boolean') {
      return val;
    } else {
      return val == 'true'
    }

    return (val === null || val === undefined) ? false : val;
  }

  loginInWithNhs(isTrue): void {
    // set the value in local storage
    // localStorage.setItem('login_with_nhs', isTrue);
    console.log('nhs login status updated');

    this.http.post(this.basePath + 'api/nhs/login-status', { status: isTrue, token: this.token }).subscribe({
      next: (response: any) => {
        console.log('nhs login status updated');
        this.nhsLoggedInStatus = isTrue;
        if (isTrue) {
          this.nhsToken = response.nhs_token;
          this.user = response.user;
        }
      },
      error: (response) => {
        console.log('error updating nhs login status');
        this.nhsLoggedInStatus = false;
      }
    });
  }

  isLoggedWithNhs(): boolean {
    // get bool nhs login
    // return true;
    return this.user.login_service == 'nhs';
  }

  refreshNhsUserInfo(): void {
    // Retrieve the token from local storage
    const token = this.nhsToken;
    if (!token) {
      console.error('No token found in local storage');
      // return;
    }
    
    // Make a backend call to /api/nhs/userinfo
    this.http
      .post(this.basePath + 'api/nhs/settings-refresh', { nhs_token: token, user: this.user, token: this.token })
      .subscribe({
        next: (response: any) => {

          // if userinfo is not changed, do nothing
          if (response.status) {
            // if userinfo is changed, update the user object
            this.user = response.user;
          }

          this.nhsToken = response.nhs_token;
          console.log('active token');
          this.isUserLogged = true;
          // localStorage.removeItem('session_start');
          // this.setSession();
          // this.setUserSettings([{ logins: response.user.logins + 1 }, { guest_id: this.guest_id }]);
          // this.resetPulseTimer(60000);
          console.log(response);
          this.router.navigateByUrl("/home");


          // if (response.user.postcode == null || response.user.postcode == 'NULL' || response.user.postcode == '') {
          //   this.router.navigateByUrl('/postcode-entry', { replaceUrl: true });
          // } else {
          //   this.accesscodes(false);
          // }
          // navigate to home page
        },
        error: (response) => {
          console.log('error updating nhs login status');
        },
      });
  }

}

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  timer;
  constructor(public router: Router, public data: DataService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.data.networkConnection != undefined && this.data.networkConnection.connected == false) {
      return EMPTY;
    }

    // let body = {};
    // for(let i of Object.keys(request.body)){
    //   if(i != 'token' && i != 'guest_id'){
    //     body[i] = request.body[i];
    //   }
    // }
    // console.log('/' + request.url.replace(this.data._basePath,'') + ': ',JSON.stringify(body));
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);
          // this.errorDialogService.openDialog(event);
          if (event.body != undefined && event.body.app_update != undefined && event.url.indexOf('userappnav') != -1 && this.data.routerHistory.length > 1) {
            console.log('update available');
            this.data.goToUpdate(event.body.app_update.title, event.body.app_update.subtitle, event.body.app_update.force);
          }

          if (event.url.indexOf('userappnav') != -1 && event.body != undefined && event.body.disable_touch_to_speak != undefined) {
            this.data.disable_touch_to_speak = event.body.disable_touch_to_speak;
          }

          if (event.url.indexOf('userappnav') != -1 && event.body != undefined && event.body.enable_app_store_rating != undefined) {
            this.data.enable_app_store_rating = event.body.enable_app_store_rating;
          }

          if (event.url.indexOf('usernavguest') != -1 && event.body != undefined && event.body.disable_nhs_login != undefined) {
            this.data.disable_nhs_login = event.body.disable_nhs_login;
          }
          if ((event.url.indexOf('usernavguest') != -1 || event.url.indexOf('userappnav') != -1) && event.body != undefined) {
            if (event.body?.enable_feedback_questionnaire != undefined) {
              this.data.enable_feedback_questionnaire = event.body.enable_feedback_questionnaire;
            }
          }

          if (event.url.indexOf('usernavguest') != -1 && event.body != undefined && event.body.enable_login_mfa != undefined) {
            this.data.enable_login_mfa = event.body.enable_login_mfa;
          }

          if (event.url.indexOf('userappnav') != -1 && event.body != undefined && event.body.enable_login_mfa != undefined) {
            this.data.enable_login_mfa = event.body.enable_login_mfa;
          }

          if (['/fakesplash','/accesscodes'].indexOf(this.router.url) == -1 && event.url.indexOf('userappnav') != -1 && event.body != undefined && event.body.user != undefined && event.body.user.reload_app != undefined && event.body.user.reload_app == '1') {
            this.data.setUserSettings([{reload_app: '0'}]);
            this.data.presentAlert('Account updated', 'Your account has been updated. Please re-select the condition.', 'OK', '');
            this.router.navigateByUrl('/accesscodes', { replaceUrl: true });
          }

          if (event.url.indexOf('usernavguest') != -1 && event.body != undefined && event.body.enable_registration_phone_validation != undefined) {
            this.data.enable_registration_phone_validation = event.body.enable_registration_phone_validation;
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('error--->>>', error);
        console.log('status--->>>', error.status);
        console.log('requestUrl---->>>', request.url);
        if (request.url.indexOf('getubetter.com') == -1) {
          return throwError(error);
        }
        if (error.status == 503) { // Maintenance mode
          this.data.maintenance_mode = true;
          return throwError(error);
        } else {
          if (this.data.maintenance_mode == true) {
            this.data.maintenance_mode = false;
          }
        }
        if (this.router.url != '/register' && (error.status == 401) && this.data.isUserLogged != undefined && this.data.isUserLogged == true) {
          console.log('logout !!!!!! !!!!');
          this.data.logout();
          return EMPTY;
        }

        if (error.error != undefined && error.error.app_update != undefined && request.url.indexOf('pulse') != -1) {
          console.log('update available');
          this.data.goToUpdate(error.error.app_update.title, error.error.app_update.subtitle, error.error.app_update.force);
        } else {

          if (request.url.indexOf('userappnav') == -1 && request.url.indexOf('pulse') == -1 && error.status != 400 && error.status != 555 && error.status != 401) {
            this.data.showAjaxRequestLoader = false;
            this.data.presentAlert('Unable to connect you', '', 'OK', 'There was a problem connecting you to the server. Please try again.');

            if (
              this.data.diag != undefined &&
              this.data.diag.platform != undefined &&
              this.data.diag.platform !== null
            ) {
              clearTimeout(this.timer);
              let storageData = { data: {}, error: '', stack: '' };
              Object.keys(localStorage).forEach(data => {
                if (['pathwaylabels', 'introslider', 'showAjaxRequestLoader', 'token', 'colors', 'first_app_open'].indexOf(data) == -1) {
                  try {
                    storageData.data[data] = JSON.parse(localStorage.getItem(data));
                  } catch (err) {
                    storageData.data[data] = localStorage.getItem(data);
                  }
                }
              });

              storageData.error = (this.data.user != undefined && this.data.user.id > 0 ? 'Uid ' + this.data.user.id + ', ' : '') + 'Gid ' + this.data.guest_id + ' ' + error.message;
              storageData.stack = JSON.stringify(error);
              console.log('storageData: ', storageData);
              this.timer = setTimeout(() => {
                this.data.apiPostPremise('saveerror', storageData).then(() => { });
              }, 3000);

            }

            return EMPTY;
          }
        }

        return throwError(error);
      }));
  }
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  timer: any;
  constructor(
    private injector: Injector
  ) { }

  handleError(error: any) {
    console.log('handdleee errorrrr !!!!');
    console.log(error);
    // Check if it's an error from an HTTP response
    let dataService = this.injector.get(DataService);
    if (
      dataService.diag != undefined &&
      dataService.diag.platform != undefined &&
      dataService.diag.platform !== null
    ) {
      if (
        error.message != undefined &&
        (
          error.message.indexOf('User denied permissions') == -1 &&
          error.message.indexOf('User cancelled photos app') == -1
        )
      ) {
        clearTimeout(this.timer);
        console.log('globalerrorhandler: ', error);
        console.log('globalerrorhandler2 : ', dataService.diag.platform);
        let storageData = { data: {}, error: '', stack: '' };
        Object.keys(localStorage).forEach(data => {
          if (['pathwaylabels', 'introslider', 'showAjaxRequestLoader', 'token', 'colors', 'first_app_open'].indexOf(data) == -1) {
            try {
              storageData.data[data] = JSON.parse(localStorage.getItem(data));
            } catch (err) {
              storageData.data[data] = localStorage.getItem(data);
            }
          }
        });

        storageData.error = (dataService.user != undefined && dataService.user.id > 0 ? 'Uid ' + dataService.user.id + ', ' : '') + 'Gid ' + dataService.guest_id + ' ' + error.message;
        if (!storageData.error) return;
        storageData.stack = error.stack;
        console.log('storageData: ', storageData);
        this.timer = setTimeout(() => {
          dataService.apiPostPremise('saveerror', storageData).then(() => { });
        }, 30000);
      }
    } else {
      throw error;
    }
  }
}
