import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
    selector: 'app-timepickermodal',
    templateUrl: './timepickermodal.component.html',
    styleUrls: ['./timepickermodal.component.scss'],
    standalone: false
})
export class TimepickermodalComponent  implements OnInit {
  public time: any;

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  close(){
    if(this.time.indexOf('T') == -1){
      this.time = moment().format('YYYY-MM-DD H:mm');
    }
    this.modalController.dismiss(this.time);
  }

  cancel(){
    this.modalController.dismiss();
  }

}
