import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

import { HttpClient } from '@angular/common/http';
import { AlertController, ModalController } from '@ionic/angular';
import { InfozonemodalComponent } from 'src/app/components/infozonemodal/infozonemodal.component';
import { VideomodalComponent } from 'src/app/components/videomodal/videomodal.component';
import { register } from 'swiper/element/bundle';
register();

@Component({
    selector: 'app-smblockslideitem',
    templateUrl: './smblockslideitem.component.html',
    styleUrls: ['./smblockslideitem.component.scss'],
    standalone: false
})
export class SmblockslideitemComponent implements OnInit {
  @Input() b: any;
  public basepath = '';
  public slides = [];
  public smodulesOptions = { width: 110, loop: false, pagination: false, spaceBetween: 5 };
  public module_id = '0';
  public smodulesSlider: any;

  constructor(
    public data: DataService,
    public router: Router,
    public route: ActivatedRoute,
    public http: HttpClient,
    public alertController: AlertController,
    public modalController: ModalController
  ) { }

  unwrap(b) {
    if (b.next)
      return [b,...this.unwrap(b.next)];
    else
      return [b];
  }

  ngOnInit() {
    console.log('this.slides ngOnInit');
    this.basepath = this.data.basePath;
    this.slides = this.unwrap(this.b);
    this.module_id = this.route.snapshot.paramMap.get("module");

    setTimeout(() => {
      if(this.smodulesSlider){
        this.smodulesSlider.swiper.update();
      } else {
        this.smodulesSlider = document.getElementById('swiper-container-'+this.b.id);
        Object.assign(this.smodulesSlider, this.smodulesOptions);
        this.smodulesSlider.initialize();
      }
    },250);
  }

  navigate(b) {
    if (b.nav_id) {
      console.log('/smpage/' + b.nav_id + '/' + this.module_id );
      this.router.navigateByUrl('/smpage/' + b.nav_id + '/' + this.module_id );
    }
  }

  async navigate2(b) {
    console.log("navigate2");
    console.log(b);
    
    let id = this.route.snapshot.paramMap.get("id");
    let module_id = this.route.snapshot.paramMap.get("module");
    this.data.visitbox(module_id, b.id);

    console.log('/smpage/' + b.nav_id + '/' + module_id)
    if (b.nav_id) {
      this.router.navigateByUrl('/smpage/' + b.nav_id + '/' + module_id);
      return;
    }
    
    if (b.video) {
      if (b.video) {
        let that = this
        let video = b.video;
        const modal = await this.modalController.create({
          component: VideomodalComponent,
          animated: false,
          componentProps: {
            video: video,
            basepath: that.data.basePath
          }
        });

        let payload = {
          token: this.data.token,
          title: 'Open video ' + video.title,
          pathway_id: that.data.pathway ? that.data.pathway.id : '',
          condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
        };
        this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
          next: function(response:any) {
            // console.log(response);
          },
          error: function(response) {
            // console.log(arguments);
          }
        })

        return await modal.present();
      }
    }

    if (b.infozone) {
      let infozone = b.infozone;
      let that = this
      const modal = await this.modalController.create({
        component: InfozonemodalComponent,
        animated: false,
        componentProps: {
          infozone: infozone,
          basepath: that.data.basePath
        }
      });

      let payload = {
        token: this.data.token,
        title: 'Open infozone ' + infozone.id,
        pathway_id: that.data.pathway ? that.data.pathway.id : '',
        condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
      };
      this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
        next: function(response:any) {
          // console.log(response);
        },
        error: function(response) {
          // console.log(arguments);
        }
      })

      return await modal.present();

    }
  }

  safeURL(item) {
    return encodeURI(item);
  }
  
  isboxVisited(sm:any) {
    return this.data.isboxVisited(sm.id, this.route.snapshot.paramMap.get("module"));
  }

}
