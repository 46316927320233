import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-questionnaireoutputmodal',
    templateUrl: './questionnaireoutputmodal.component.html',
    styleUrls: ['./questionnaireoutputmodal.component.scss'],
    standalone: false
})
export class QuestionnaireoutputmodalComponent implements OnInit {

  public response:any;
  public basepath:any;
  public redirecturl:any;
  public button_disabled = false;

  constructor(public router: Router, public modalController: ModalController, public data: DataService) { }

  ngOnInit() {
    console.log(this)
    console.log(this.redirecturl)
  }

  gotomenu(){
    this.modalController.dismiss()  
    this.router.navigateByUrl('/mainmenu',{replaceUrl: true});
  }

  closeMenu(){
    console.log('closeMenu!')
    this.modalController.dismiss()  
  }

  page(){
    let that = this
    that.button_disabled = true;
    // this.modalController.dismiss()  
    if (this.response.output.action.page) {
        switch(this.response.output.action.page) {
          case 'programonboarding':
            {
              this.data.refreshRecovery().then((response) => {
                console.log('done');
                that.button_disabled = false;
                that.router.navigate(['/programonboarding/'+that.response.output.program.id],{replaceUrl: true});
                that.modalController.dismiss();
              })
            }
            break;
          case 'inbox':
            // code block
            {
              this.data.refreshRecovery().then((response) => {
                console.log('done');
                that.button_disabled = false;
                that.router.navigate(['/'+that.response.output.action.page],{replaceUrl: true});
                that.modalController.dismiss();
              })
            }
            break;
          case 'listing':
            {
              this.data.refreshRecovery().then((response) => {
                console.log('done');
                that.button_disabled = false;
                that.router.navigate(['/treatmentshop/organisation/'+that.response.output.action.listing_id], { replaceUrl: true }) 
                // that.router.navigateByUrl('/treatmentshop/organisation/'+that.response.output.action.listing_id)
                that.modalController.dismiss()  
              })
            }
            break;
          default:
            {
              console.log('reached questionaire output modal: ', this.response.output.action.page);
              that.button_disabled = false;
              this.router.navigate(['/'+this.response.output.action.page], {replaceUrl: true})
              this.modalController.dismiss()  
            }
        }
    }
    // if(this.response.output.action.page && this.response.output.action.page == 'programonboarding')
    // {
    //   this.data.refreshRecovery().then((response) => {
    //     console.log('done')
    //     that.router.navigateByUrl('/programonboarding/'+that.response.output.program.id)
    //     that.modalController.dismiss()  
    //   })
    // }
    // else {
    //   if(this.response.output.action.page && this.response.output.action.page == 'inbox')
    //   {
    //     this.data.refreshRecovery().then((response) => {
    //       console.log('done')
    //       that.router.navigateByUrl('/'+that.response.output.action.page)
    //       that.modalController.dismiss()  
    //     })
    //   }
    //   else
    //   {
    //     this.router.navigateByUrl('/'+this.response.output.action.page)
    //     this.modalController.dismiss()  
    //   }
    // }
  }

  gotoinbox(){
    let that = this;
    that.button_disabled = true;
    this.data.refreshRecovery().then((response) => {
      // that.router.navigateByUrl('/inbox')
      that.button_disabled = false;
      that.router.navigate(['/inbox'], { replaceUrl: true });
      that.modalController.dismiss()  
    })
  }

  validPhone(){
    if(this.response.output.action.phone == '[GP_PHONE]' && this.data.user.gp_phone){
      return this.data.user.gp_phone;
    }

    if(this.response.output.action.phone){
      return this.response.output.action.phone;
    }

    return false;
  }

  ok(){
    let that = this;
    that.button_disabled = true;
    this.data.showAjaxRequestLoader = true;
    this.data.refreshRecovery().then(function(){
      that.data.showAjaxRequestLoader = false;
      that.button_disabled = false;
      if(that.redirecturl)
        that.router.navigateByUrl(that.redirecturl, {replaceUrl: true});
      else{
        if(that.data.progress == undefined || that.data.progress.code == undefined){
          that.router.navigateByUrl('/feedbackinput', {replaceUrl: true})
        }else
          that.router.navigateByUrl('/home', {replaceUrl: true});
      }
      that.modalController.dismiss()  
    }).catch(function(){
      that.button_disabled = false;
      that.data.showAjaxRequestLoader = false;
      if(that.redirecturl)
        that.router.navigateByUrl(that.redirecturl, {replaceUrl: true});
      else{
        if(that.data.progress == undefined || that.data.progress.code == undefined){
          that.router.navigateByUrl('/feedbackinput', {replaceUrl: true})
        }else
          that.router.navigateByUrl('/home', {replaceUrl: true});
      }
      that.modalController.dismiss()  
    })
  }

  call(){
    window.open('tel:' + this.validPhone());
  }
}
