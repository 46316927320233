import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textFormat',
    standalone: false
})
export class TextFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // Replace ">>>" with a line break.
    value = value.replace(/>>>/g, '<br/>');

    /* 
      Expected syntax:
      tag1:tag2:...:[text to transform]
      Example: b:i:underline:[This text is bold, italic and underlined]
    */
    const transformRegex = /([a-zA-Z0-9:_\-\(\)]+):\[([^\]]+)\]/g;

    return value.replace(transformRegex, (_, tagGroup, textInside) => {
      let result = textInside;
      const wrappers: { open: string, close: string }[] = [];
      const styleMap: { [key: string]: string } = {};

      // Process each tag from the tag group.
      tagGroup.split(':').forEach(tag => {
        this.processTag(tag.toLowerCase(), wrappers, styleMap);
      });

      // Nest the semantic wrappers.
      wrappers.forEach(w => result = w.open + result + w.close);

      // Combine inline styles in a single span.
      const styleString = Object.entries(styleMap)
        .map(([prop, val]) => `${prop}:${val}`)
        .join(';');
      if (styleString) {
        result = `<span style="${styleString}">${result}</span>`;
      }
      return result;
    });
  }

  private processTag(tag: string, wrappers: { open: string, close: string }[], styleMap: { [key: string]: string }): void {
    // Semantic tags mapping.
    const semanticMapping: { [key: string]: { open: string, close: string } } = {
      'b': { open: '<strong>', close: '</strong>' },
      'bold': { open: '<strong>', close: '</strong>' },
      'i': { open: '<em>', close: '</em>' },
      'italic': { open: '<em>', close: '</em>' },
      'u': { open: '<u>', close: '</u>' },
      'underline': { open: '<u>', close: '</u>' },
      's': { open: '<s>', close: '</s>' },
      'strike': { open: '<s>', close: '</s>' },
      'h1': { open: '<h1 style="margin:0;padding:0;">', close: '</h1>' },
      'h2': { open: '<h2 style="margin:0;padding:0;">', close: '</h2>' },
      'h3': { open: '<h3 style="margin:0;padding:0;">', close: '</h3>' }
    };

    // Inline style mappings.
    const styleMapping: { [key: string]: string } = {
      'fs1': 'font-size:120%',
      'fs2': 'font-size:140%',
      'red': 'color:red',
      'green': 'color:green',
      'blue': 'color:blue'
    };

    // Check for semantic formatting.
    if (semanticMapping[tag]) {
      wrappers.push(semanticMapping[tag]);
      return;
    }

    // Check for inline style.
    if (styleMapping[tag]) {
      const [cssProp, cssVal] = styleMapping[tag].split(':');
      styleMap[cssProp] = cssVal;
      return;
    }

    // Background color styling: e.g., b_red, b_green, etc.
    if (tag.startsWith('b_')) {
      const bgColor = tag.substring(2);
      styleMap['background-color'] = bgColor;
      return;
    }

    // Hyperlink: syntax link(http://example.com)
    if (tag.startsWith('link(') && tag.endsWith(')')) {
      const url = tag.slice(5, -1);
      wrappers.push({ open: `<a href="${url}" target="_blank">`, close: '</a>' });
      return;
    }

    // Button: syntax button(http://example.com)
    if (tag.startsWith('button(') && tag.endsWith(')')) {
      const url = tag.slice(7, -1);
      wrappers.push({ open: `<button onclick="window.location.href='${url}'">`, close: '</button>' });
      return;
    }

    // Additional features can be added here as needed.
  }
}