import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-smblockimage',
    templateUrl: './smblockimage.component.html',
    styleUrls: ['./smblockimage.component.scss'],
    standalone: false
})
  
export class SmblockimageComponent implements OnInit {
  @Input() b: any;
  public basepath = '';

  constructor(
    public data: DataService
  ) {
  }

  ionViewWillEnter() {
    console.log(' will this.data.basePath');
    console.log(this.data.basePath);
    this.basepath = this.data.basePath;
  }

  ngOnInit() {
    console.log(' init this.data.basePath');
    console.log(this.data.basePath);
    this.basepath = this.data.basePath;
  }
  
  safeURL(item) {
    return encodeURI(item);
  }
}
