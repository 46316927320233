import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  // disable console.log in production
  console.log = (..._args: any[]) => { };
}
// console.log = (..._args: any[]) => { };


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));