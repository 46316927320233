// Updated VideoitemComponent (videoitem.component.ts)
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy, AfterViewInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import Player from '@vimeo/player';
import { DataService } from 'src/app/services/data.service';
import { VimeoService } from 'src/app/services/vimeo.service';

@Component({
    selector: 'app-videoitem',
    templateUrl: './videoitem.component.html',
    styleUrls: ['./videoitem.component.scss'],
    standalone: false
})
export class VideoitemComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() slide: any;
  @Input() video: any;
  @Input() autoplay: boolean = false;
  @Input() index: number; // Slide index
  @Input() seen: boolean = true; // Whether the video has been seen
  @Input() change = 0; // Change detection

  // Toggle to use Vimeo API (default is true)
  @Input() useVimeoPlayerApi: boolean = true;

  // Emit events when video begins and stops.
  @Output() videoPlaying = new EventEmitter<void>();
  @Output() videoStopped = new EventEmitter<void>();

  public vimeoId: string = ''; // e.g. "/123456789"
  public embedUrl: SafeResourceUrl = '';
  @Input() showVideo = true;
  public withImage: boolean = false;
  private path: string = '';
  static player: Player | null = null;

  private routerEventsSub: Subscription | null = null;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private data: DataService,
    private vimeoService: VimeoService
  ) {
    // On route change, ensure any active video stops.
    this.routerEventsSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log('Destroying video item');
        this.stopVideo();
        this.stopVideoBeforeThis();
      }
    });
  }

  ngOnInit() {
    this.updateEmbedUrl();
    // this.playVideo();
  }

  ngAfterViewInit() {
    if (this.useVimeoPlayerApi) {
      const iframe = document.getElementById(this.getVimeoID()) as HTMLIFrameElement;
      if (iframe) {
        const player = this.vimeoService.initPlayer(iframe);
        
        // Listen for play events.
        // player.on('play', () => {
        //   this.videoPlaying.emit();
        // });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['router']) {
      console.log('Destroying video item');
      this.stopVideo();
      this.stopVideoBeforeThis();
    }
  }

  ngOnDestroy() {
    console.log('Destroying video item');
    this.stopVideo();
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
  }

  updateEmbedUrl(): void {
    if (!this.video && this.slide?.video) {
      this.video = this.slide.video;
    }
    if (this.video?.vimeovideo) {
      this.vimeoId = this.video.vimeovideo.startsWith('/')
        ? this.video.vimeovideo
        : '/' + this.video.vimeovideo;
      const url = `https://player.vimeo.com/video${this.vimeoId}`;
      this.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  getVimeoID(): string {
    // if (!this.index) {
    //   return 'vimeo-player';
    // }
    // console.log('vimeo-player-' + this.index);
    return 'vimeo-player-' + this.index;
  }

  getVimeoPlayerIdBeforeThis(vimeoid: string | null = null): string {
    // if (!this.index) {
    //   console.log('thevimeoid', 'vimeo-player');
    //   return 'vimeo-player';
    // }
    // if (vimeoid) {
    //   return 'vimeo-player';
    // }
    return 'vimeo-player-' + (((this.index > 0) ? this.index : 1) - 1);
  }

  playVideo(): void {
    // Stop any other playing video.
    this.vimeoService.stopCurrentPlayer().finally(() => {
      this.showVideo = true;
      if (!this.useVimeoPlayerApi) {
        this.videoPlaying.emit();
        return;
      }
      setTimeout(() => {
        const iframe = document.getElementById(this.getVimeoID()) as HTMLIFrameElement;
        if (iframe) {
          const player = this.vimeoService.initPlayer(iframe);
          player.play().catch(() => {});
        } else {
          this.videoPlaying.emit();
        }
      }, 400);
    });
  }

  pauseVideo(): void {
    setTimeout(() => {
      const iframe = document.getElementById(this.getVimeoID()) as HTMLIFrameElement;
      if (iframe) {
        const player = this.vimeoService.initPlayer(iframe);
        player.play().catch(() => { });
      } else {
        this.videoPlaying.emit();
      }
    }, 1000);
  }

  stopVideo(): void {
    setTimeout(() => {
      const iframe = document.getElementById(this.getVimeoID()) as HTMLIFrameElement;
      if (iframe) {
        const player = this.vimeoService.initPlayer(iframe);
        player.unload().catch(() => { });
      } else {
        this.videoPlaying.emit();
      }
    }, 400);
  }

  stopVideoBeforeThis(vimeoid: string|null = null): void {
    setTimeout(() => {
      const iframe = document.getElementById(this.getVimeoPlayerIdBeforeThis(vimeoid)) as HTMLIFrameElement;
      if (iframe) {
        const player = this.vimeoService.initPlayer(iframe);
        player.unload().catch(() => { });
      } else {
        this.videoPlaying.emit();
      }
    }, 400);
  }
}