import { Component, OnInit, Sanitizer } from '@angular/core';
import { NavParams, ModalController, Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-videomodal',
    templateUrl: './videomodal.component.html',
    styleUrls: ['./videomodal.component.scss'],
    standalone: false
})
export class VideomodalComponent implements OnInit {
  
  video: any;

  public response:any;
  public basepath:any;
  public redirecturl:any;
  public vimeolink:any;
  public showAjaxRequestLoader;
  public showSpinnerLoader;
  public timeoutIframeload: any;


  constructor(public platform: Platform, public data: DataService, navParams: NavParams, public modalController: ModalController, public sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    console.log(this.video)
    this.vimeolink = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video'+ this.video.vimeovideo + (this.video.extra_params ? this.video.extra_params : ''));
  }

  ionViewWillEnter(){
    this.showAjaxRequestLoader = true;
    this.timeoutIframeload = setTimeout(() => {
      this.showSpinnerLoader = true;
    },5000);
  }

  iframeLoaded(){
    clearTimeout(this.timeoutIframeload);
    this.showAjaxRequestLoader = false;
    this.showSpinnerLoader = false;
  }

  platformWidth(){
    if(!(this.platform.is('android') || this.platform.is('ios')))
      return 640;
    else
      return 320
  }

  platformHeight(){
    if(!(this.platform.is('android') || this.platform.is('ios')))
      return 320;
    else
      return 240
  }

  vimeowidth(){
    let ratio = this.video.width/this.video.height;

    return this.platformWidth();
  }

  vimeoheight(){
    let ratio = this.video.width/this.video.height;

    return ratio > 1 ? 240 : 480;
  }

  get vimeolink_(){
    // console.log('https://player.vimeo.com/video'+ this.video.vimeovideo);
    // console.log(this.video);  

    if( this.video.vimeovideo )
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video'+ this.video.vimeovideo);
    else
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/407508746');
    

    return this.sanitizer.bypassSecurityTrustHtml('https://player.vimeo.com/video/'+ this.video.vimeovideo);
  }

  cancel(){
    console.log(this.data.diag)
    this.modalController.dismiss();
    this.data.rateApp();
  }
}
