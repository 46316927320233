import { Directive } from '@angular/core';

@Directive({
    selector: '[appVideo]',
    standalone: false
})
export class VideoDirective {

  constructor() { }

}
