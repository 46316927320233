import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalController } from '@ionic/angular';
import { ExercisemodalComponent } from '../../components/exercisemodal/exercisemodal.component'
import { VideomodalComponent } from '../../components/videomodal/videomodal.component'
import { InfozonemodalComponent } from '../infozonemodal/infozonemodal.component';

@Component({
    selector: 'app-exerciseitem',
    templateUrl: './exerciseitem.component.html',
    styleUrls: ['./exerciseitem.component.scss'],
    standalone: false
})
export class ExerciseitemComponent implements OnInit {
  @Input() exercise: any;

  constructor(public modalController:ModalController, public data: DataService) { 
  }

  ngOnInit() {
    console.log(" ngOnInit this.exercise.thumb " + this.exercise.id)
  }

  select_(){
    console.log(this.video)
  }

  get video() {
    return this.exercise.video;
  }

  reseter(exercise){
    console.log("reseter");
    // console.log(this.exercise.current.id+'-1');
    // this.exercise = exercise;
    // console.log(this.exercise.current.id+'-2');
    // this.data.pullAppContent(['endpoint']).then(() => {
    //   this.data.pullAppContent(['smodules']);
    // });
  }

  async selectinfozone(){
    let that = this
    const modal = await this.modalController.create({
      component: InfozonemodalComponent,
      animated: false,
      componentProps: {
        infozone: that.exercise.infozone,
        basepath: that.data.basePath
      }
    });

    let payload = {
      token: this.data.token,
      title: 'Open infozone '+this.exercise.infozone_id,
      pathway_id: that.data.pathway ? that.data.pathway.id : '',
      condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
    };
    this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
      next: function(response:any) {
        // console.log(response);
      },
      error: function(response) {
        // console.log(arguments);
      }
    })

    return await modal.present();
  }

  async selectvideo(){
    let that = this
    const modal = await this.modalController.create({
      component: VideomodalComponent,
      animated: false,
      componentProps: {
        video: that.exercise.video,
        basepath: that.data.basePath
      }
    });

    let payload = {
      token: this.data.token,
      title: 'Open video '+this.exercise.video.title,
      pathway_id: that.data.pathway ? that.data.pathway.id : '',
      condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : '',

      extra_params: {
        exercise_id: that.exercise?.id,
        video_id: that.exercise?.video ? that.exercise?.video?.id : '',
        name: that.exercise?.video ? that.exercise?.video?.title : '',
      }
    };

    console.log('exercisebutton payload');

    this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
      next: function(response:any) {
        // console.log(response);
      },
      error: function(response) {
        // console.log(arguments);
      }
    })

    return await modal.present();
  }


  async select(){
    let that = this
    const modal = await this.modalController.create({
      component: ExercisemodalComponent,
      animated: false,
      componentProps: {
        video: that.video,
        exercise: that.exercise,
        reseter: this.reseter,
        basepath: that.data.basePath
      }
    });

    let payload = {
      token: this.data.token,
      title: 'Open Exercise '+this.video.id,
      pathway_id: that.data.pathway ? that.data.pathway.id : '',
      condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
    };
    this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
      next: function(response:any) {
        // console.log(response);
      },
      error: function(response) {
        // console.log(arguments);
      }
    })

    return await modal.present();
  }

   thumbpath(){
    if(this.exercise.thumb)
      return encodeURI(this.data.basePath + 'storage/app/media' + this.exercise.thumb);
    else
      return encodeURI(this.data.basePath + 'storage/app/media' + this.video.thumb);
  }
}