import { Component, OnInit, Sanitizer } from '@angular/core';
import { NavParams, ModalController, Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-exercisemodal',
    templateUrl: './exercisemodal.component.html',
    styleUrls: ['./exercisemodal.component.scss'],
    standalone: false
})
export class ExercisemodalComponent implements OnInit {
  
  video: any;
  exercise: any;
  reseter: any;
  timeout: any;

  public response:any;
  public basepath:any;
  public redirecturl:any;
  public vimeourl: any;
  public levels:any =[

  ];

  constructor(public platform: Platform, public data: DataService, navParams: NavParams, public modalController: ModalController, public sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    console.log('modal loaded')
    this.vimeourl = this.vimeolink();
  }

  async tooeasy(){
    console.log('too easy');
    let self = this;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      let response:any = await self.data.exerciselevel(self.exercise, "+");
      self.exercise = response.exercise;
      self.video = self.exercise['video'+self.exercise.getCurrentLevel];
      self.vimeourl = self.vimeolink();

      self.data.exerciseLevel.emit("changed",response.exercise);
      self.reseter(self.exercise);
      console.log(response);
    },200);

  }

  async toohard(){
    console.log('too hard');
    let self = this;
    this.timeout = setTimeout(async () => {
      let response:any = await self.data.exerciselevel(self.exercise, "-");
      self.exercise = response.exercise;
      self.video = self.exercise['video'+self.exercise.getCurrentLevel];
      self.vimeourl = self.vimeolink();
      self.data.exerciseLevel.emit("changed",response.exercise);
      self.reseter(self.exercise);

      console.log(response);
    },200);
  }

  get step(){
    // return typeof this.exercise.map;
    let current = 1;
    for(let i = 0; i < this.exercise.map.length; i++){
      if(this.exercise.getCurrentLevel == this.exercise.map[i]){
        current = i+1;
      }
    }

    return current + "/" + this.exercise.map.length;
    return "1/12";
  }

  platformWidth(){
    if(!(this.platform.is('android') || this.platform.is('ios')))
      return 640;
    else
      return 320
  }

  platformHeight(){
    if(!(this.platform.is('android') || this.platform.is('ios')))
      return 320;
    else
      return 240
  }

  vimeowidth(){
    let ratio = this.video.width/this.video.height;

    return this.platformWidth();
  }

  vimeoheight(){
    let ratio = this.video.width/this.video.height;

    return ratio > 1 ? 240 : 480;
  }

  vimeolink(){
    if( this.video.vimeovideo )
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video'+ this.video.vimeovideo);
    else
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/407508746');
  }

  cancel(){
    this.modalController.dismiss()
    this.data.exerciseLevel.emit("changed");
    this.data.rateApp();
  }
}
