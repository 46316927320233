<div style="position: relative; margin-bottom: 20px;">
  <!-- Thumbnail + play button view -->
  <div *ngIf="!showVideo && withImage" style="cursor: pointer;" (click)="playVideo()">
    <img [src]="slide?.image?.path || 'assets/img/placeholder.jpg'" alt="thumbnail" width="100%" />
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="45" height="45" rx="22.5" fill="#2799FA" />
        <path d="M18 15V31L31 23L18 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </div>

  <!-- Vimeo iframe view -->
  <div style="position: relative;">
    <iframe 
        [id]="getVimeoID()"
        mozallowfullscreen="true"
        webkitallowfullscreen="true" allowfullscreen="true"
        [src]="embedUrl" width="100%" height="240" frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
    </iframe>
  </div>
</div>