import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { InfozonemodalComponent } from 'src/app/components/infozonemodal/infozonemodal.component';
import { VideomodalComponent } from 'src/app/components/videomodal/videomodal.component';

@Component({
    selector: 'app-smblocklistitem',
    templateUrl: './smblocklistitem.component.html',
    styleUrls: ['./smblocklistitem.component.scss'],
    standalone: false
})
export class SmblocklistitemComponent implements OnInit {
  @Input() b: any;
  public basepath = '';

  constructor(
    public data: DataService,
    public router: Router,
    public route: ActivatedRoute,
    public http: HttpClient,
    public alertController: AlertController,
    public modalController: ModalController
  ) {
  }

  ionViewWillEnter() {
    console.log(' will this.data.basePath');
    console.log(this.data.basePath);
    this.basepath = this.data.basePath;
  }

  ngOnInit() {
    console.log(' init this.data.basePath');
    console.log(this.b);
    console.log(this.data.basePath);
    this.basepath = this.data.basePath;
  }

  async navigate() {
    let id = this.route.snapshot.paramMap.get("id");
    let module_id = this.route.snapshot.paramMap.get("module");
    this.data.visitbox(module_id, this.b.id);

    console.log('/smpage/' + this.b.nav_id + '/' + module_id)
    if (this.b.nav_id) {
      this.router.navigateByUrl('/smpage/' + this.b.nav_id + '/' + module_id);
      return;
    }

    if (this.b.video) {
      let that = this
      let video = this.b.video;
      const modal = await this.modalController.create({
        component: VideomodalComponent,
        animated: false,
        componentProps: {
          video: video,
          basepath: that.data.basePath
        }
      });

      let payload = {
        token: this.data.token,
        title: 'Open video ' + video.title,
        pathway_id: that.data.pathway ? that.data.pathway.id : '',
        condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
      };
      this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
        next: function(response:any) {
          // console.log(response);
        },
        error: function(response) {
          // console.log(arguments);
        }
      })

      return await modal.present();
    }

    if (this.b.infozone) {
      let infozone = this.b.infozone;
      let that = this
      const modal = await this.modalController.create({
        component: InfozonemodalComponent,
        animated: false,
        componentProps: {
          infozone: infozone,
          basepath: that.data.basePath
        }
      });

      let payload = {
        token: this.data.token,
        title: 'Open infozone ' + infozone.id,
        pathway_id: that.data.pathway ? that.data.pathway.id : '',
        condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
      };
      this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
        next: function(response:any) {
          // console.log(response);
        },
        error: function(response) {
          // console.log(arguments);
        }
      })

      return await modal.present();

    }


  }

  safeURL(item) {
    return encodeURI(item);
  }

  isboxVisited() {
    return this.data.isboxVisited(this.b.id, this.route.snapshot.paramMap.get("module"));
  }

}