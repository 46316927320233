import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-bullets',
    templateUrl: './bullets.component.html',
    styleUrls: ['./bullets.component.scss'],
    standalone: false
})
export class BulletsComponent implements OnInit {
  @Input() total = 4;
  @Input() current = 0;

  constructor() {}

  ngOnInit() {}

  // Return an array of indexes [0,1,2,...]
  get bulletIndexes(): number[] {
    return [...Array(this.total).keys()];
  }

  // Calculate percentage for the “completed” bar
  get progressPercent(): number {
    if (this.total <= 1) return 0;
    // e.g. if current=2, total=5 => progress=2/(5-1)=50%
    return (this.current / (this.total - 1)) * 100;
  }
}
