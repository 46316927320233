import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AlertController, ModalController } from '@ionic/angular';
import { QuestionnaireoutputmodalComponent } from '../../components/questionnaireoutputmodal/questionnaireoutputmodal.component'


@Component({
    selector: 'app-numericquestionnaire',
    templateUrl: './numericquestionnaire.component.html',
    styleUrls: ['./numericquestionnaire.component.scss'],
    standalone: false
})
export class NumericquestionnaireComponent implements OnInit {

  @Input() questionnaire:any;
  @Output() submited = new EventEmitter<any>();
  public button_disabled = false;

  constructor(public data: DataService, public alertController: AlertController, public modalController:ModalController) { 

  }

  ngOnInit() {}

  segmentChanged(event){
    console.log(event)
    console.log(this)
    // this.extra = this.questionnaire.blockquestions.filter((q) => q.answer == '1' )
  }

  async presentAlert(header:string, subHeader:string, button:string, response:string) {

    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: response,
      buttons: [button]
    });

    await alert.present();
  }


  async submit(){
    console.log("submit");
    this.button_disabled = true;
    let answersLeft = this.questionnaire.numericquestions.filter((q) => !q.answer)
    if(answersLeft && answersLeft.length){
      
      this.presentAlert('Questionnaire warning', '', 'OK', 'Please answer to all questions');
      this.button_disabled = false;
    }else{
      let q_res = this.questionnaire.numericquestions.map((q) => {
        return {
          'id': q.id,
          'answer': q.answer
        }
      });
      this.data.showAjaxRequestLoader = true;
      console.log({done: true, type: this.questionnaire.type, id: this.questionnaire.id, questions: q_res})
      this.submited.emit({done: true, type: this.questionnaire.type, id: this.questionnaire.id, questions: q_res})
      let response = await this.data.questionnaire({done: true, type: this.questionnaire.type, id: this.questionnaire.id, questions: q_res})
      console.log(response);

      const modal = await this.modalController.create({
        component: QuestionnaireoutputmodalComponent,
        animated: false,
        componentProps: {
          response: response,
          basepath: this.data.basePath
        }
      });
      this.button_disabled = false;
      return await modal.present();
        
    }
  }

  selectValue(q, value){
    console.log('selectValue')
    console.log(q, value);
    q.answer = value;
  }

}
