// Updated VimeoService (vimeo.service.ts)
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Player from '@vimeo/player';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {
  // Static player instance to always have a single playing video.
  private static currentPlayer: Player | null = null;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * Generate a trusted Vimeo embed URL from a video object containing `vimeovideo`.
   */
  public generateIframeUrl(video: any, autoplay: boolean = false): SafeResourceUrl {
    if (!video?.vimeovideo) {
      return '';
    }
    const idOrPath = video.vimeovideo.startsWith('/') ? video.vimeovideo : '/' + video.vimeovideo;
    let url = `https://player.vimeo.com/video${idOrPath}`;
    if (autoplay) {
      url += '?autoplay=1';
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   * Initialize a Vimeo Player using the provided iframe.
   * If another player exists, stop it before creating a new one.
   */
  public initPlayer(iframe: HTMLIFrameElement): Player {
    if (VimeoService.currentPlayer) {
      VimeoService.currentPlayer.unload().catch(() => { });
    }
    const newPlayer = new Player(iframe);
    VimeoService.currentPlayer = newPlayer;
    return newPlayer;
  }

  /**
   * Return the current player instance.
   */
  public getCurrentPlayer(): Player | null {
    return VimeoService.currentPlayer;
  }

  /**
   * Stop and unload the current player.
   */
  public stopCurrentPlayer(): Promise<void> {
    if (VimeoService.currentPlayer) {
      return VimeoService.currentPlayer.unload().then(() => {
        VimeoService.currentPlayer = null;
      }).catch(() => {
        VimeoService.currentPlayer = null;
      });
    }
    return Promise.resolve();
  }

}