import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalController } from '@ionic/angular';
import { ExercisemodalComponent } from '../../components/exercisemodal/exercisemodal.component'

@Component({
    selector: 'app-exercisebutton',
    templateUrl: './exercisebutton.component.html',
    styleUrls: ['./exercisebutton.component.scss'],
    standalone: false
})
export class ExercisebuttonComponent implements OnInit {
  @Input() exercise: any;

  constructor(public modalController:ModalController, public data: DataService) { 
  }

  ngOnInit() {
    console.log(" ngOnInit this.exercise.thumb " + this.exercise.id)
  }

  select_(){
    console.log(this.video)
  }

  get video(){
    return this.exercise.video;
  }

  reseter(exercise){
    console.log("reseter");
    // console.log(this.exercise.current.id+'-1');
    // this.exercise = exercise;
    // console.log(this.exercise.current.id+'-2');
    // this.data.refreshRecovery().then((response) => {
    //   console.log('done reseter')
    // })
    // this.data.pullAppContent(['endpoint']).then(() => {
    //   this.data.pullAppContent(['smodules']);
    // });
  }

  async select(){
    let that = this
    const modal = await this.modalController.create({
      component: ExercisemodalComponent,
      animated: false,
      componentProps: {
        video: that.video,
        exercise: that.exercise,
        reseter: this.reseter,
        basepath: that.data.basePath
      }
    });

    let payload = {
      token: this.data.token,
      title: 'Open Exercise '+this.video.id,
      pathway_id: that.data.pathway ? that.data.pathway.id : '',
      condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
    };
    this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
      next: function(response:any) {
        // console.log(response);
      },
      error: function(response) {
        // console.log(arguments);
      }
    })

    return await modal.present();
  }

   thumbpath(){
    if(this.exercise.thumb)
      return encodeURI(this.data.basePath + 'storage/app/media' + this.exercise.thumb);
    else
      return encodeURI(this.data.basePath + 'storage/app/media' + this.video.thumb);
  }
}